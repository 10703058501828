import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    root: {
      backgroundColor: '#4893FF',
      color: 'white',
      borderRadius: 5,
      padding: '4px 8px',
      fontSize: 14,
      textTransform: 'none',
      lineHeight: 'normal',
      letterSpacing: 0.28,
      fontWeight: 400,
      '&:hover': {
        backgroundColor: '#4893FF',
      },
    },
    wrapperRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    infoIcon: {
      fontSize: 16,
      color: '#1C1B1F',
      opacity: 0.5,
    },
  },
  { name: 'RTEVariableChip' }
);
