import Popover from '@material-ui/core/Popover';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import classnames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';

import useStyles from '../styles';

import MobileModal from '../../MobileModal/MobileModal';
import { ISelectOption, ISingleSelectProps } from '../types';

const SingleSelect = ({
  value,
  onChange,
  error,
  helperText,
  options,
  label,
  fullWidth,
  width,
  headerComponent,
  footerComponent,
  renderOption,
  renderSelectedValue,
  mobileModalTitle,
  disabled = false,
  classes: propClasses = {},
}: ISingleSelectProps) => {
  const isDesktop = useMediaQuery('(min-width: 767px)');
  const [anchor, setAnchor] = useState(null);
  const offeringWrapRef = useRef(null);

  const onOpen = useCallback(
    (event) => setAnchor(event.currentTarget),
    [setAnchor]
  );
  const onClose = useCallback(() => setAnchor(null), [setAnchor]);

  const handleChange = useCallback(
    (item, itemOption) => {
      onChange(item, itemOption);
      onClose();
    },
    [onChange, onClose]
  );

  const valueMap = useMemo(
    () =>
      options.reduce((acc: any, option: ISelectOption) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [options]
  );

  const classes = useStyles({
    showPlaceholder: !value,
    error,
    fullWidth,
    width: fullWidth ? '100%' : width,
    offeringWrapWidth: offeringWrapRef.current?.offsetWidth,
  });

  const content = (
    <div
      className={classnames(
        classes.contentWrap,
        classes.scroll,
        propClasses.contentWrap
      )}
    >
      {headerComponent}

      {options.map((option, index, arr) => {
        if (!renderOption)
          return <div className={propClasses.option}>{option.label}</div>;
        return renderOption({
          option,
          value,
          onChange: (v) => handleChange(v, valueMap[v]),
          isLast: index === arr.length - 1,
          isSelected: value === option.value,
          index,
        });
      })}

      {footerComponent}
    </div>
  );

  return (
    <div
      ref={offeringWrapRef}
      className={classnames(classes.outerWrap, propClasses.wrapper)}
    >
      {label && (
        <div
          className={classnames(
            classes.formTitle,
            classes.mb_2,
            propClasses.label
          )}
        >
          {label}
        </div>
      )}

      <div
        className={classnames(classes.offeringDropWrap, propClasses.dropdown)}
        onClick={!disabled ? onOpen : () => {}}
      >
        <div
          className={classnames(
            classes.selectedTitles,
            propClasses.selectinWrapper
          )}
        >
          {renderSelectedValue({
            selectedValue: value,
            selectedOption: valueMap[value],
          })}
        </div>
        <KeyboardArrowDownRoundedIcon
          className={classnames(classes.selectIcon, propClasses.selectIcon)}
        />
      </div>

      {helperText && (
        <div
          className={classnames(
            classes.helper,
            classes[error ? 'errorWrapper' : 'helperWrapper'],
            propClasses.helperTextWrapper
          )}
        >
          {error && (
            <WarningRoundedIcon
              preserveAspectRatio="none"
              viewBox="3 4 19.06 17.01"
              className={classnames(classes.errorIcon, propClasses.errorIcon)}
            />
          )}
          <span
            className={classnames(
              classes[error ? 'errorText' : 'helperText'],
              propClasses.helperText
            )}
          >
            {helperText}
          </span>
        </div>
      )}

      {isDesktop ? (
        <Popover
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          classes={{
            paper: classnames(
              classes.popover_paper,
              classes.scroll,
              propClasses.popoverPaper
            ),
          }}
        >
          {content}
        </Popover>
      ) : (
        <MobileModal
          open={anchor}
          onClose={onClose}
          header={mobileModalTitle}
          customFooter={<></>}
          paperClassName={classnames(
            classes.drawerPaper,
            propClasses.mobileModalPaper
          )}
        >
          {content}
        </MobileModal>
      )}
    </div>
  );
};

export default SingleSelect;
