import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (globaltheme: any) => ({
    root: {
      padding: globaltheme.spacing(0), // this keeps icon at correct position
      width: ({ fullWidth }: any) => (fullWidth ? '100%' : ''),
      fontFamily: globaltheme?.typography?.fontFamily,
    },
    labelWrapper: {
      fontSize: '14px',
      marginBottom: '8.75px',
      lineHeight: '17px',
      boxSizing: 'border-box',
      color: globaltheme?.palette?.secondary?.main,
      fontFamily: globaltheme?.typography?.fontFamily,
    },
    input: {
      color: globaltheme?.palette?.secondary?.main,
      borderRadius: ({ showCurrency }) =>
        showCurrency ? '0 4px 4px 0' : '4px',
      '&:hover': {
        background: globaltheme?.palette?.secondary?.shade_50,
        '& $notchedOutline': {
          border: `1.5px solid ${globaltheme?.palette?.secondary?.shade_200}`,
        },
      },
      '&.Mui-focused': {
        background: globaltheme?.palette?.basic?.white,
        '& $notchedOutline': {
          border: `1.5px solid ${globaltheme?.palette?.primary?.main}`,
        },
      },
    },
    inputElement: {
      padding: ({ size }) => `${size === 'small' ? '10.5px' : '6.5px'} 14px`,
      borderRadius: ({ showCurrency }) =>
        showCurrency ? '0 4px 4px 0' : '4px',
    },
    notchedOutline: {
      border: `1.5px solid ${globaltheme?.palette?.secondary?.shade_200}`,
    },
    error: {
      border: `1.5px solid ${globaltheme?.palette?.basic?.danger_red} !important`,
    },
    thin: {
      height: globaltheme.spacing(7),
      fontSize: '14px',
    },
    small: {
      height: globaltheme.spacing(8),
      fontSize: globaltheme.spacing(5),
    },
    helper: {
      lineHeight: '14px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      fontFamily: globaltheme?.typography?.fontFamily,
    },
    helperWrapper: {
      marginTop: '10px',
    },
    helperText: {
      fontSize: globaltheme.spacing(4),
      color: globaltheme?.palette?.secondary?.main,
    },
    errorWrapper: {
      marginTop: '7.28px',
      gap: globaltheme.spacing(3),
    },
    errorIcon: {
      width: globaltheme.spacing(5),
      height: '14px',
      color: globaltheme?.palette?.basic?.danger_red,
    },
    errorText: {
      fontSize: '11px',
      color: globaltheme?.palette?.basic?.danger_red,
    },
    inputStartIcon: {
      marginLeft: '6.525px',
    },
    inputEndIcon: {
      marginRight: '12.475px',
    },
    popoverPaperRoot: {
      padding: '16px',
      width: '100%',
    },
    wrapper: {
      width: ({ fullWidth }: any) => (fullWidth ? '100%' : ''),
      display: 'flex',
      alignItems: 'center',
    },
    currencyInnerWrapper: {
      background: '#D9D9D9',
      height: globaltheme.spacing(8),
      borderRadius: '5px 0 0 5px',
      minWidth: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: ({ currencyWrapWidth }) => currencyWrapWidth,
    },
    formControlClassName: {
      '& svg': {
        color: globaltheme?.palette?.secondary?.main,
      },
    },
    inputBaseRootClassName: {
      background: '#D9D9D9',
      border: 'none',
      '&.Mui-focused': {
        border: 'none',
      },
    },
    selectClassName: {
      background: '#D9D9D9',
      color: globaltheme?.palette?.secondary?.main,
      marginRight: '4px',
      paddingRight: '6px !important',
      paddingLeft: '8px',
      '&:focus': {
        background: '#D9D9D9',
      },
    },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
