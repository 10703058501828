import { Editor, Range, Point, Element } from 'slate';
import { TYPES } from '../utils/SlateUtilityFunctions';

const withTable = (editor: any) => {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  editor.deleteBackward = (unit: any) => {
    const { selection } = editor;
    if (selection) {
      const [cell] = Array.from(
        Editor.nodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === TYPES.TABLE_CELL,
        })
      );

      const prevNodePath = Editor.before(editor, selection);

      const [tableNode]: any = Editor.nodes(editor, {
        at: prevNodePath,
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement &&
          n.type === TYPES.TABLE_CELL,
      });

      if (cell) {
        const [, cellPath] = cell;

        const start = Editor.start(editor, cellPath);
        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
      if (!cell && tableNode) {
        return;
      }
    }

    deleteBackward(unit);
  };
  editor.deleteForward = (unit: any) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Array.from(
        Editor.nodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === TYPES.TABLE_CELL,
        })
      );

      const prevNodePath = Editor.after(editor, selection);
      const [tableNode]: any = Editor.nodes(editor, {
        at: prevNodePath,
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement &&
          n.type === TYPES.TABLE_CELL,
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
      if (!cell && tableNode) {
        return;
      }
    }

    deleteForward(unit);
  };

  editor.insertBreak = () => {
    const { selection } = editor;
    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === TYPES.TABLE,
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };
  return editor;
};

export default withTable;
