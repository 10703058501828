import { makeStyles } from '@material-ui/core';

interface IStyle {
  isDesktop?: boolean;
}

export const useStyles = makeStyles((theme: any) => ({
  dialogContentClassName: {
    padding: ({ isDesktop }: IStyle) => (isDesktop ? '24px' : '16px'),
  },
  modalPaperClassName: {
    width: '70%',
  },
  codeToText: {
    width: '100%',
    height: '90%',
    display: 'grid',
    gridTemplateColumns: '45% 10% 45%',
  },
  textOutput: {
    background: theme?.palette?.basic?.white,
    overflow: 'scroll',
    maxHeight: '332px',
  },
}));
