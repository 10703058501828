import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { ProgressProps } from './Progress';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globaltheme: any) => ({
    root: {
      width: '100%',
    },
    labelStepperRoot: {
      padding: 0,
      width: '100%',
      alignItems: 'flex-end',
    },
    stepperRoot: {
      padding: 0,
      width: '100%',
    },
    stepLabelRoot: {
      flexDirection: 'column-reverse !important' as any,
    },
    stepContentRoot: {
      border: 'none',
      margin: 0,
      padding: 0,
      marginTop: '6px',
      textAlign: 'center',
      fontSize: '14px',
    },
    label: {
      fontSize: '14px',
      color: globaltheme?.palette?.basic?.black,
      opacity: 0.5,
    },
    labelActive: {
      fontSize: '14px',
      color: globaltheme?.palette?.basic?.black,
      opacity: 1,
      fontWeight: 400,
    },
    labelCompleted: {
      opacity: 1,
    },
    textBlack: {
      fill: globaltheme?.palette?.basic?.black,
      fontSize: '14px',
    },
    textWhite: {
      fill: globaltheme?.palette?.basic?.white,
      fontSize: '14px',
    },
    stepIconPropsRoot: {
      color: 'white',
      fontWeight: 400,
      fontSize: '24px',
      border: `1.5px solid ${globaltheme?.palette?.basic?.black}`,
      boxSizing: 'border-box',
      borderRadius: '50%',
    },
    stepIconPropsCompleted: {
      visibility: 'visible',
      color: `${globaltheme?.palette?.fine_pine?.main} !important`,
      border: 'none',
    },
    stepIconPropsActive: {
      border: 'none',
      color: `${globaltheme?.palette?.primary?.main} !important`,
    },
    stepRoot: {
      padding: 0,
    },
    iconContainer: {
      paddingRight: 0,
      cursor: 'pointer !important',
    },
    iconContainerWithPadding: {
      paddingRight: '4px',
      cursor: 'pointer !important',
    },
    labelContainer: {
      marginBottom: '4px',
    },
    alternativeLabel: {
      marginTop: '0 !important',
    },
    hide: {
      display: 'none',
    },
    disabled: {
      opacity: 0.5,
      cursor: 'not-allowed !important',
    },
  }),
  { name: 'ComponentLibrary' }
);

const AlternateProgressLabelled: FC<
  Omit<ProgressProps, 'variant' | 'stepsCount'>
> = ({
  options,
  activeStep,
  onClick,
  stepperClassName,
  stepClassName,
  stepLabelClassName,
  stepContentClassName,
  hideProgress,
  ...restProps
}) => {
  const classes = useStyles();

  const { nonLinear } = restProps;

  const handleStep = (step: number) => (e: React.SyntheticEvent) => {
    onClick && onClick(e, step);
  };

  const Connector = withStyles((globaltheme: any) => ({
    alternativeLabel: {
      top: '10.5px',
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
    },
    active: {
      '& $line': {
        backgroundColor: globaltheme?.palette?.primary?.main,
      },
    },
    completed: {
      '& $line': {
        backgroundColor: globaltheme?.palette?.fine_pine?.main,
      },
    },
    disabled: {
      '& $line': {
        backgroundColor: globaltheme?.palette?.fine_pine?.main,
        opacity: 0.5,
      },
    },
    line: {
      height: '2px',
      border: 'none',
      backgroundColor: globaltheme?.palette?.secondary?.shade_200,
    },
  }))(StepConnector);

  const getProgress = (index: number) =>
    Math.floor(((index + 1) / options.length) * 100);

  return (
    <div className={classes.root}>
      <Stepper
        className={classnames(classes.labelStepperRoot, stepperClassName)}
        alternativeLabel
        activeStep={activeStep}
        connector={<></>}
        {...restProps}
      >
        {options.map((title, index) => (
          <Step
            key={index}
            {...(nonLinear && {
              completed: activeStep !== index && title.completed,
            })}
            className={classnames(classes.stepRoot, stepClassName)}
          >
            <StepLabel
              classes={{
                root: classnames(classes.stepLabelRoot, stepLabelClassName),
                label: classnames(
                  classes.label,
                  activeStep === index && classes.labelActive
                ),
                iconContainer:
                  activeStep === index
                    ? classes.iconContainerWithPadding
                    : classes.iconContainer,
                labelContainer: classes.labelContainer,
                alternativeLabel: classes.alternativeLabel,
                completed: classes.labelCompleted,
              }}
              StepIconProps={{
                classes: {
                  root: classes.hide,
                  text:
                    activeStep === index
                      ? classes.textWhite
                      : classes.textBlack,
                  active: classes.stepIconPropsActive,
                  completed: classes.stepIconPropsCompleted,
                },
              }}
              onClick={handleStep(index)}
            >
              {nonLinear ? title.title : title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Stepper
        className={classnames(classes.stepperRoot, stepperClassName)}
        alternativeLabel
        activeStep={activeStep}
        connector={<Connector />}
        {...restProps}
      >
        {options.map((title, index) => (
          <Step
            key={index}
            {...(nonLinear
              ? {
                  completed: activeStep !== index && title.completed,
                  disabled: index > 0 && options[index - 1].disabled,
                }
              : { disabled: false })}
            className={classnames(classes.stepRoot, stepClassName)}
          >
            <StepLabel
              classes={{
                root: classnames(classes.stepLabelRoot, stepLabelClassName),
                label: classes.label,
                iconContainer: classnames(
                  activeStep === index
                    ? classes.iconContainerWithPadding
                    : classes.iconContainer,
                  title.disabled && classes.disabled
                ),
                alternativeLabel: classes.alternativeLabel,
              }}
              StepIconProps={{
                classes: {
                  root: classes.stepIconPropsRoot,
                  text:
                    activeStep === index
                      ? classes.textWhite
                      : classes.textBlack,
                  active: classes.stepIconPropsActive,
                  completed: classes.stepIconPropsCompleted,
                },
              }}
              onClick={handleStep(index)}
            />
            {!hideProgress && (
              <StepContent
                TransitionProps={{ in: true }}
                className={classnames(
                  classes.stepContentRoot,
                  stepContentClassName
                )}
              >
                {index === 0 && activeStep === 0
                  ? '0%'
                  : index < activeStep && `${getProgress(index)}%`}
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withGenerateClassName(AlternateProgressLabelled);
