import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  color?: string;
}

const useStyles = makeStyles(
  (theme: any) => ({
    wrapper: {
      width: '20.41px',
      height: '20.41px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: ({ error, checked, color: inputColor }: StyleProps) => {
        let border = '1px solid ';
        let color = theme?.palette?.secondary?.shade_200;
        if (checked) color = inputColor || theme?.palette?.primary?.main;
        if (error) color = theme?.palette?.basic?.danger_red;
        return border + color;
      },
      boxSizing: 'border-box',
      borderRadius: '50%',
      '&:hover': {
        border: ({ checked, color }) =>
          theme.spacing(
            1,
            'solid',
            checked
              ? theme?.palette?.persian_blue?.shade_400
              : color || theme?.palette?.primary?.main
          ),
      },
      overflow: 'hidden',
      opacity: ({ disabled }: StyleProps) => (disabled ? 0.5 : 1),
      cursor: ({ disabled }: StyleProps) =>
        disabled ? 'not-allowed' : 'pointer',
      background: ({ checked, error, color }: StyleProps) =>
        checked
          ? error
            ? theme?.palette?.basic?.danger_red
            : color || theme?.palette?.primary?.main
          : theme?.palette?.basic?.white,
    },
    root: {
      padding: 0,
      '&, & svg, & input': {
        width: '16.41px',
        height: '16.41px',
        boxSizing: 'border-box',
      },
      '& svg': {
        color: ({ color }) => color || theme?.palette?.primary?.main,
      },
    },
    checkedIcon: {
      width: '16.41px',
      height: '16.41px',
      boxSizing: 'border-box',
      borderRadius: '50%',
      background: theme?.palette?.basic?.white,
      border: ({ error, color }) =>
        `4.6px solid ${
          error
            ? theme?.palette?.basic?.danger_red
            : color || theme?.palette?.primary?.main
        }`,
    },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
