import { FC } from 'react';
import Box from '@material-ui/core/Box';
import MuiRadio, { RadioProps as MuiRadioProps } from '@material-ui/core/Radio';

import useStyles from './Radio.styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';

export interface RadioProps extends MuiRadioProps {
  error?: boolean;
  wrapperClassName?: string;
  radioClassName?: string;
}

const Radio: FC<RadioProps> = ({
  checked = false,
  error,
  wrapperClassName,
  radioClassName,
  ...restProps
}) => {
  const { disabled, color } = restProps;
  const classes = useStyles({ error, checked, disabled, color });
  const icon = <></>;
  const checkedIcon = <Box className={classes.checkedIcon} />;
  return (
    <Box className={[classes.wrapper, wrapperClassName].join(' ')}>
      <MuiRadio
        icon={icon}
        checked={checked}
        checkedIcon={checkedIcon}
        classes={{ root: [classes.root, radioClassName].join(' ') }}
        {...restProps}
      />
    </Box>
  );
};

export default withGenerateClassName(Radio);
