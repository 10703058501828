import { Transforms, Path, Node } from 'slate';
import { TYPES } from '../utils/SlateUtilityFunctions';

const withEmbeds = (editor: any) => {
  const { isVoid, insertBreak } = editor;

  editor.isVoid = (element: any) =>
    ['video', TYPES.IMAGE, TYPES.HTML_CODE].includes(element.type)
      ? true
      : isVoid(element);

  editor.insertBreak = (...args: any) => {
    const parentPath = Path.parent(editor.selection.focus.path);
    const parentNode = Node.get(editor, parentPath);
    if (editor.isVoid(parentNode)) {
      const nextPath = Path.next(parentPath);
      Transforms.insertNodes(
        editor,
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
        {
          at: nextPath,
          select: true, // Focus on this node once inserted
        }
      );
    } else {
      insertBreak(...args);
    }
  };
  return editor;
};

export default withEmbeds;
