import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import React, { FC } from 'react';
import { ProgressProps } from './Progress';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = (
  mobile: boolean,
  hasDescription: boolean,
  connector: string,
  stepIconSize: string
) =>
  makeStyles(
    (globaltheme: any) => ({
      root: {
        position: 'relative',
        width: '100%',
      },
      stepRoot: {
        padding: 0,
      },
      stepperRoot: {
        justifyContent: 'space-between',
        padding: 0,
        width: '100%',
        background: 'none',
      },
      stepLabelRoot: {
        display: 'flex',
        alignItems: mobile || !hasDescription ? 'center' : 'normal',
        boxSizing: 'border-box',
        paddingBottom: '8px',
        cursor: 'pointer !important',
      },
      setLabelActive: {
        borderBottom:
          connector === 'arrow'
            ? `3px solid ${globaltheme?.palette?.primary?.main}`
            : 'none',
      },
      stepLabel: {
        fontSize: hasDescription && connector === 'arrow' ? '16px' : '14px',
        color: `${globaltheme?.palette?.basic?.black} !important`,
        opacity: '0.5',
        fontWeight: `${mobile ? 400 : 500} !important` as any,
      },
      labelActive: {
        color: `${globaltheme?.palette?.primary?.main} !important`,
        opacity: '1',
        marginRight: mobile ? '4px' : 0,
      },
      labelCompleted: {
        color: `${globaltheme?.palette?.secondary?.main} !important`,
        opacity: '1',
      },
      stepIconPropsTextBlack: {
        fill: globaltheme?.palette?.basic?.black,
        fontSize: '14px',
      },
      stepIconPropsTextWhite: {
        fill: globaltheme?.palette?.basic?.white,
        fontSize: '14px',
      },
      stepIconPropsRoot: {
        color: 'white',
        fontWeight: 400,
        fontSize: stepIconSize === 'medium' ? '20px' : '32px',
        border: `1.5px solid ${globaltheme?.palette?.basic?.black}`,
        boxSizing: 'border-box',
        borderRadius: '50%',
        opacity: connector === 'arrow' && hasDescription ? 0.25 : 1,
      },
      stepIconPropsCompleted: {
        visibility: 'visible',
        color:
          connector === 'arrow' && hasDescription
            ? `${globaltheme?.palette?.basic?.white} !important`
            : `${globaltheme?.palette?.fine_pine?.main} !important`,
        border:
          connector === 'arrow' && hasDescription
            ? `1px solid ${globaltheme?.palette?.primary?.main}`
            : 'none',
        background:
          connector === 'arrow' && hasDescription
            ? `${globaltheme?.palette?.primary?.main}`
            : 'inherit',
        opacity: 1,
      },
      stepIconPropsActive: {
        border: 'none',
        color: `${globaltheme?.palette?.primary?.main} !important`,
        marginRight: mobile ? '4px' : '0',
        opacity: 1,
      },
      iconContainer: {
        paddingRight: 0,
        marginRight: mobile ? '0' : '8px',
        marginTop:
          mobile || !hasDescription
            ? '0'
            : stepIconSize === 'medium'
            ? '3px'
            : '-5px',
      },
      optional: {
        position: connector === 'arrow' ? 'static' : 'absolute',
        fontSize: '14px',
        color: `${globaltheme?.palette?.basic?.black} !important`,
        opacity: '0.5',
      },
      optionalActive: {
        opacity: hasDescription && connector === 'arrow' ? 0.85 : 0.5,
      },
      arrowIcon: {
        color: globaltheme?.palette?.secondary?.shade_100,
        marginTop: '-10px',
      },
      bottomBar: {
        width: '100%',
        position: 'absolute',
        borderBottom: `2px solid ${globaltheme?.palette?.secondary?.shade_100}`,
        bottom: 0,
        zIndex: -1,
      },
    }),
    { name: 'ComponentLibrary' }
  );

const Labelled: FC<Omit<ProgressProps, 'variant' | 'stepsCount'>> = ({
  mobile = false,
  hasDescription = false,
  connector = 'line',
  stepIconSize = 'medium',
  options,
  activeStep,
  onClick,
  progressClassName,
  stepperClassName,
  stepClassName,
  stepLabelClassName,
  descriptionClassName,
  ...restProps
}) => {
  const classes = useStyles(mobile, hasDescription, connector, stepIconSize)();

  const { nonLinear } = restProps;

  const handleStep = (step: number) => (e: React.SyntheticEvent) => {
    onClick && onClick(e, step);
  };

  const Connector = withStyles((globaltheme: any) => {
    const styles = {
      line: {
        height: '2px',
        border: 'none',
        backgroundColor: globaltheme?.palette?.secondary?.shade_200,
        marginTop:
          hasDescription && stepIconSize === 'large' ? '-8.5px' : '-4.5px',
        marginLeft: mobile ? '0' : '8px',
        marginRight: mobile ? '0' : '12px',
      },
    };

    const mobileStyleProps = {
      active: {
        '& $line': {
          backgroundColor: globaltheme?.palette?.primary?.main,
        },
      },
      completed: {
        '& $line': {
          backgroundColor: globaltheme?.palette?.fine_pine?.main,
        },
      },
    };

    return { ...styles, ...(!mobile && mobileStyleProps) } as any;
  })(StepConnector);

  return (
    <div className={classnames(classes.root, progressClassName)}>
      {connector === 'arrow' && <div className={classes.bottomBar}></div>}
      <Stepper
        className={classnames(classes.stepperRoot, stepperClassName)}
        activeStep={activeStep}
        connector={
          connector === 'arrow' ? (
            <ArrowForwardIosRoundedIcon className={classes.arrowIcon} />
          ) : (
            <Connector />
          )
        }
        {...restProps}
      >
        {options.map(({ title, description, completed }, index) => {
          const descriptionContainer = (
            <Typography
              className={classnames(
                classes.optional,
                descriptionClassName,
                activeStep === index && classes.optionalActive
              )}
              variant="caption"
            >
              {description}
            </Typography>
          );
          return (
            <Step
              className={classnames(classes.stepRoot, stepClassName)}
              key={index}
              {...(nonLinear && activeStep !== index && { completed })}
            >
              <StepLabel
                classes={{
                  root: classnames(
                    classes.stepLabelRoot,
                    stepLabelClassName,
                    activeStep === index && classes.setLabelActive
                  ),
                  label: classes.stepLabel,
                  iconContainer: classes.iconContainer,
                  active: classes.labelActive,
                  completed: classes.labelCompleted,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.stepIconPropsRoot,
                    text:
                      activeStep === index
                        ? classes.stepIconPropsTextWhite
                        : classes.stepIconPropsTextBlack,
                    completed: classes.stepIconPropsCompleted,
                    active: classes.stepIconPropsActive,
                  },
                }}
                onClick={handleStep(index)}
                optional={!mobile && hasDescription && descriptionContainer}
              >
                {mobile ? activeStep === index && title : title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default withGenerateClassName(Labelled);
