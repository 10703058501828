import Interweave from 'interweave';
import React from 'react';

interface HtmlCodeSerializeProps {
  attributes: any;
  element: any;
}

const HtmlCodeSerialize: React.FC<HtmlCodeSerializeProps> = (props) => {
  const { attributes, element, children } = props;
  return (
    <div {...attributes} {...element.attr}>
      <div contentEditable={false}>
        <Interweave content={element.html} />
      </div>
      {children}
    </div>
  );
};

export default HtmlCodeSerialize;
