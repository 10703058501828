import { Transforms } from 'slate';
import { deserialize, deserializer } from '../utils/deserializer';
import { serializer } from '../utils/serializer';
import {
  checkHtmlContainsComments,
  isCopyFromDoc,
} from '../RichTextEditorV2.utils';
import { GOOGLE_DOC_REGEX, HTML_COMMENT_REGEX } from '../regex';

export const withHtml = (editor: any) => {
  const { insertData } = editor;

  editor.insertData = (data: any) => {
    let html = data.getData('text/html');

    if (checkHtmlContainsComments(html)) {
      // Remove comments by replacing them with an empty string
      html = html.replace(HTML_COMMENT_REGEX, '');
    }

    if (isCopyFromDoc(html)) {
      // Remove google doc id and bold tag by replacing them with an empty string
      html = html.replace(GOOGLE_DOC_REGEX, '');
      html = html.replace('</b>', '');
    }

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const fragment = deserializer(serializer(deserialize(parsed.body)));
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};
