import MuiSwitch, {
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core/Switch';
import ClearRounded from '@material-ui/icons/ClearRounded';
import CheckRounded from '@material-ui/icons/CheckRounded';

import classnames from 'classnames';
import React from 'react';
import useStyles from './Switch.styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface SwitchProps extends Omit<MuiSwitchProps, 'size' | 'color'> {
  rootWrapperClassName?: any;
  switchClassName?: any;
  size?: 'small' | 'large';
  color?: 'primary' | 'success';
  customSwitchBase?: React.ReactNode;
  noIcon?: boolean;
}

const Switch = ({
  rootWrapperClassName,
  switchClassName,
  size = 'small',
  color = 'primary',
  defaultChecked,
  customSwitchBase,
  noIcon,
  disabled,
  ...restProps
}: SwitchProps) => {
  const { checked } = restProps;
  const classes = useStyles({ checked, size, color, disabled });

  return (
    <div className={classnames(classes.rootWrapper, rootWrapperClassName)}>
      <div className={classes.switchBase}>
        {customSwitchBase ||
          (!noIcon && (
            <>
              <CheckRounded />
              <ClearRounded />
            </>
          ))}
      </div>
      <MuiSwitch
        classes={{
          root: classnames(classes.root, switchClassName),
          switchBase: classes.muiSwitchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...restProps}
      />
    </div>
  );
};

export default withGenerateClassName(Switch);
