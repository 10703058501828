import React from 'react';
import { getJsxStyles } from '../SlateUtilityFunctions';

interface LinkSerializeProps {
  attributes: any;
  element: any;
}

const LinkSerialize: React.FC<LinkSerializeProps> = ({
  attributes,
  element,
  children,
}) => {
  const styles: any = getJsxStyles(element?.style?.value);

  return (
    <a
      href={element.href}
      {...attributes}
      {...element.attr}
      target={element.target}
      style={styles}
    >
      {children}
    </a>
  );
};

export default LinkSerialize;
