import React, { ReactElement, useEffect, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import classnames from 'classnames';

import Dropdown from '../Dropdown/Dropdown';
import Input from '../Input/Input';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import { formatNumbeWithCommasString, pluralise } from '../../../utils/utils';

interface IStyle {
  bottom?: string;
  borderedTop?: boolean;
}

const useStyles = makeStyles(
  (theme: any) => ({
    container: {
      height: '52px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 16px',
      backgroundColor: theme?.palette?.basic?.white,
      borderTop: ({ borderedTop }: IStyle) =>
        borderedTop
          ? `1.5px solid ${theme?.palette?.secondary?.shade_100}`
          : '',
      bottom: ({ bottom }: IStyle) => bottom,
      position: ({ bottom }: IStyle) => (bottom ? 'fixed' : undefined),
      fontFamily: theme?.typography?.fontFamily,
    },
    leftSection: {
      display: 'flex',
      alignItems: 'center',
      gap: '8.5px',
      color: theme?.palette?.secondary?.main,
      fontSize: '14px',
      lineHeight: '17px',
    },
    bold: {
      fontWeight: 600,
    },
    rightSection: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
    disabledCursor: {
      cursor: 'not-allowed',
    },
    action: {
      border: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      padding: '0px',
      width: '32px',
      height: '32px',
      boxSizing: 'border-box',
      borderRadius: '2px',
      '& svg': {
        width: '14.25px',
        height: '12.99px',
      },
    },
    nonDisabledAction: {
      '& svg': {
        color: theme?.palette?.basic?.black,
      },
    },
    inputSection: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.65)',
    },
    pageInput: {
      width: '41px',
      '& input': {
        padding: '7px 8px 8px 8px',
      },
    },
    formControlClassName: {
      '& svg': {
        color: theme?.palette?.secondary?.main,
        width: '14px',
        height: '9.72px',
      },
    },
    inputBaseRootClassName: {
      border: 'none',
      '&.Mui-focused': {
        border: 'none',
      },
    },
    selectClassName: {
      color: theme?.palette?.secondary?.main,
      fontSize: '14px',
      paddingLeft: '0',
      paddingRight: '4.5px !important',
    },
  }),
  { name: 'ComponentLibrary' }
);

export interface IPaginationProps {
  rowsPerPageOptions?: number[];
  showAll?: boolean;
  limit?: ReactElement | null;
  loading?: boolean;
  page: number;
  perPage: number;
  total: number;
  setPage: (a: number) => void;
  setPerPage: (a: number) => void;
  labelDisplayedRows?: (
    from: number,
    to: number,
    total: number
  ) => ReactElement;
  wrapperClassName?: string;
  bottom?: string;
  borderedTop?: boolean;
  entityName?: string;
}

const MobilePagination = ({
  rowsPerPageOptions = [],
  showAll,
  limit = null,
  loading,
  page,
  perPage,
  total,
  setPage,
  setPerPage,
  labelDisplayedRows,
  wrapperClassName,
  bottom,
  borderedTop,
  entityName = "item"
}: IPaginationProps) => {
  const classes = useStyles({ bottom, borderedTop });
  const [pageInput, setPageInput] = useState<string>(String(page));

  useEffect(() => {
    setPageInput(String(page));
  }, [page]);

  const totalPages = Math.ceil(total / perPage) || 1;
  const from = (page - 1) * perPage + 1;
  const to = Math.min(page * perPage, total);
  const isPrevButtonDisabled = page <= 1;
  const isNextButtonDisabled = page >= totalPages;

  const onPrevButtonClick = () => setPage(page - 1);
  const onNextButtonClick = () => setPage(page + 1);

  let perPageDropdownOptions;
  if (rowsPerPageOptions.length) {
    perPageDropdownOptions = rowsPerPageOptions.map((value) => {
      const stringValue = String(value);
      return {
        id: stringValue,
        label: stringValue,
        value: stringValue,
      };
    });
    if (showAll) {
      const stringTotalValue = String(total);
      perPageDropdownOptions.push({
        id: stringTotalValue,
        label: 'All',
        value: stringTotalValue,
      });
    }
  }

  const handlePerPageChange = (value: string) => {
    setPerPage(Number(value));
    setPage(1);
  };

  const onPageInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPageInput(event.target.value);

  const handlePageUpdate = () => {
    const pageValue = parseInt(pageInput);
    if (isNaN(pageValue) || pageValue < 1 || pageValue > totalPages)
      setPageInput(String(page));
    else setPage(pageValue);
  };

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading ? <Toolbar variant="dense" /> : limit;
  }

  return (
    <div className={classnames(classes.container, wrapperClassName)}>
      <div className={classes.leftSection}>
        {rowsPerPageOptions.length ? (
          <Dropdown
            value={perPage}
            handleChange={handlePerPageChange}
            options={perPageDropdownOptions}
            formControlClassName={classes.formControlClassName}
            selectClassName={classes.selectClassName}
            inputBaseRootClassName={classes.inputBaseRootClassName}
            IconComponent={() => (
              <KeyboardArrowDownIcon preserveAspectRatio="none" />
            )}
          />
        ) : null}
        {labelDisplayedRows ? (
          labelDisplayedRows(from, to, total)
        ) : (
          <div>
            <span className={classes.bold}>{`${from}-${to}`}</span>
            {' of '}
            <span className={classes.bold}>
              {total !== -1 ? formatNumbeWithCommasString(total) : `more than ${to}`}
            </span>
            &nbsp;{pluralise(entityName,total)}
          </div>
        )}
      </div>
      <div className={classes.rightSection}>
        <span className={isPrevButtonDisabled ? classes.disabledCursor : ''}>
          <IconButton
            disabled={isPrevButtonDisabled}
            onClick={onPrevButtonClick}
            classes={{ root: classes.action }}
            className={classnames(
              classes.action,
              !isPrevButtonDisabled && classes.nonDisabledAction
            )}
          >
            <ArrowBackIosRoundedIcon preserveAspectRatio="none" />
          </IconButton>
        </span>

        <div className={classes.inputSection}>
          <span>Go to</span>
          <Input
            value={pageInput}
            onChange={onPageInputChange}
            size="thin"
            onBlur={handlePageUpdate}
            inputClassName={classes.pageInput}
          />
          <span>Page</span>
        </div>

        <span className={isNextButtonDisabled ? classes.disabledCursor : ''}>
          <IconButton
            disabled={isNextButtonDisabled}
            onClick={onNextButtonClick}
            component="span"
            className={classnames(
              classes.action,
              !isNextButtonDisabled && classes.nonDisabledAction
            )}
          >
            <ArrowForwardIosRoundedIcon preserveAspectRatio="none" />
          </IconButton>
        </span>
      </div>
    </div>
  );
};

export default withGenerateClassName(MobilePagination);
