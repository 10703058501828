import { Popover } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { Editor, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import LibButton from '../../../Button/Button';
import Input from '../../../Input/Input';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import { activeMark, addMarkData } from '../../utils/SlateUtilityFunctions.js';
import { useStyles } from './ColorPicker.styles';
import { colors } from './defaultColors.js';

const logo = {
  color: <Icon icon="color" />,
  bgColor: <Icon icon="bgColor" />,
};

interface ColorPickerProps {
  format: 'color' | 'bgColor';
  editor: any;
}

const ColorPicker = ({ format, editor }: ColorPickerProps) => {
  const classes = useStyles();

  const [selection, setSelection] = useState();
  const [hexValue, setHexValue] = useState<string>('');
  const [validHex, setValidHex] = useState<boolean>();
  const colorPickerRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  const isValideHexSix = new RegExp('^#[0-9A-Za-z]{6}');
  const isValideHexThree = new RegExp('^#[0-9A-Za-z]{3}');

  const setFocus = (selection: any) => {
    if (selection) {
      setTimeout(() => {
        if (Range.isCollapsed(selection)) {
          Transforms.select(editor, selection);
          ReactEditor.focus(editor);
        }
      }, 50);
    }
  };

  const isColorActive = () => {
    const marks: any = Editor.marks(editor);

    return marks?.[format];
  };

  const removeColor = (e: React.SyntheticEvent) => {
    e.preventDefault();

    Editor.removeMark(editor, format);

    Range.isCollapsed(selection) && setFocus(selection);

    setShowOptions(false);
  };

  const changeColor = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const clickedColor = e.target.getAttribute('data-value');
    selection && Transforms.select(editor, selection);

    addMarkData(editor, { format, value: clickedColor });
    setShowOptions(false);
  };

  const toggleOption = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSelection(editor.selection);
    selection && ReactEditor.focus(editor);
    setShowOptions((prev) => !prev);
  };

  const handleFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!validHex) return;
    selection && Transforms.select(editor, selection);

    addMarkData(editor, { format, value: hexValue });
    setShowOptions(false);
    setValidHex(false);
    setHexValue('');
    selection && ReactEditor.focus(editor);
  };

  const handleHexChange = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const newHex = e.target.value;
    setValidHex(isValideHexSix.test(newHex) || isValideHexThree.test(newHex));
    setHexValue(newHex);
  };

  // handle color because on copy paste from word in to RTE background color set to transparent
  // i.e. bgColor icon hide from toolbar
  const handleColor = () => {
    if (showOptions) {
      return 'black';
    }
    if (activeMark(editor, format) === 'transparent' && format === 'bgColor') {
      return 'inherit';
    }
    return activeMark(editor, format);
  };

  return (
    <div ref={colorPickerRef}>
      <Button
        style={{
          color: handleColor(),
          opacity: '1',
        }}
        onMouseDown={toggleOption}
      >
        {logo[format]}
      </Button>

      <Popover
        classes={{
          paper: classes.paper,
        }}
        anchorEl={colorPickerRef.current}
        open={showOptions}
        onClose={() => {
          setFocus(selection);
          setShowOptions(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          <div className={classes.colorOptions}>
            {colors.map((color, index) => {
              return (
                <div
                  key={index}
                  data-value={color}
                  onMouseDown={changeColor}
                  className={classes.option}
                  style={{ background: color }}
                />
              );
            })}
          </div>

          {isColorActive() && (
            <LibButton
              buttonWrapperClassName={classes.removeBtn}
              color="danger"
              size="thin"
              startIcon={<Delete />}
              onMouseDown={removeColor}
              fullWidth
            >
              Remove color
            </LibButton>
          )}

          <p style={{ textAlign: 'center', opacity: '0.7', width: '100%' }}>
            OR
          </p>

          <div className={classes.form}>
            <div
              className={classes.option}
              style={{ background: validHex ? hexValue : '#000000' }}
            />

            <Input
              wrapperClassName={classes.input}
              size="thin"
              type="text"
              placeholder="#000000"
              value={hexValue}
              onChange={handleHexChange}
              error={!validHex}
            />

            <Button
              style={{ color: validHex ? 'green' : '' }}
              onClick={handleFormSubmit}
            >
              <Icon icon="check" />
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ColorPicker;
