import React from 'react';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import { isMarkActive, toggleMark } from '../../utils/SlateUtilityFunctions';

interface MarkButtonProps {
  format: string;
  editor: any;
}

export const MarkButton: React.FC<MarkButtonProps> = ({
  format,
  editor,
  ...restProps
}) => {
  return (
    <Button
      active={isMarkActive(editor, format)}
      format={format}
      onMouseDown={(e) => {
        e.preventDefault();
        toggleMark(editor, format);
      }}
      {...restProps}
    >
      <Icon icon={format} />
    </Button>
  );
};
