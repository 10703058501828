import React, { useEffect, useState } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import Button from '../common/Button';
import Icon from '../common/Icon';
import Embed from '../Elements/Embed/Embed';
import LinkButton from '../Elements/Link/LinkButton';
import TableSelector from '../Elements/Table/TableSelector';
import {
  activeMark,
  isBlockActive,
  TYPES,
} from '../utils/SlateUtilityFunctions.js';
import defaultToolbarGroups from './toolbarGroups.js';

import { useStyles } from './Toolbar.styles';

import { Popover, useMediaQuery } from '@material-ui/core';
import { BlockButton } from './components/BlockButton';
import Divider from './components/Divider';
import { MarkButton } from './components/MarkButton';
import { CustomDropdown } from './components/CustomDropdown';
import useFormat from '../utils/customHooks/useFormat';
import { ToolbarDropdown } from './components/ToolbarDropdown';
import ColorPicker from '../Elements/ColorPicker/ColorPicker';

import classnames from 'classnames';
import { Range, Transforms } from 'slate';

interface AdditionalAction {
  icon: React.ReactElement;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface ToolbarProps {
  handleCodeToText: Function;
  toolbarPosition: 'top' | 'bottom';
  isSmallToolbar: boolean;
  additionalToolbarActions?: AdditionalAction[];
  customToolbarClassName?: string;
  showBorder?: boolean;
  imageUploadCallback?: (val: React.SyntheticEvent | string) => null;
  filterToolbarGroups?: string[];
}

const Toolbar: React.FC<ToolbarProps> = ({
  handleCodeToText,
  toolbarPosition,
  isSmallToolbar,
  additionalToolbarActions,
  customToolbarClassName,
  showBorder,
  imageUploadCallback,
  filterToolbarGroups,
  ...restProps
}) => {
  const isDesktop = useMediaQuery('(min-device-width: 767px)');
  const editor: any = useSlate();
  const { selection: editorSelection } = editor;

  const isTable = useFormat(editor, TYPES.TABLE);
  const [toolbarGroups, setToolbarGroups] = useState<any>(defaultToolbarGroups);

  const classes = useStyles({
    toolbarPosition,
    showBorder,
    isDesktop,
  });

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selection, setSelection] = useState<any>(null);

  const handleFilterToolbarGroups = (filterNames?: string[]) => {
    if (!filterNames || filterNames.length === 0) {
      return defaultToolbarGroups; // return all elements if no filter provided
    }
    const filteredGroups = defaultToolbarGroups.map((grp: any) =>
      grp.filter(
        (element: any) =>
          //groups/elements that are not want inside the toolbar of RTE
          !filterNames.includes(element.name)
      )
    );
    return filteredGroups.filter((elem: any) => elem.length) || [];
  };
  useEffect(() => {
    let filteredGroups = [...handleFilterToolbarGroups(filterToolbarGroups)];
    if (isTable) {
      filteredGroups = toolbarGroups.map((grp: any) =>
        grp.filter(
          (element: any) =>
            //groups that are not supported inside the table
            !['codeToText'].includes(element.type)
        )
      );
      filteredGroups = filteredGroups.filter((elem) => elem.length);
    }
    setToolbarGroups(filteredGroups);
  }, [isTable, filterToolbarGroups]);

  const getDivider = (index: number) => {
    if (index === toolbarGroups.length - 1 || toolbarGroups.length === 1)
      return null;
    return <Divider />;
  };

  const AdditionalActions = additionalToolbarActions?.map(
    (action: AdditionalAction) => <Button {...action}>{action.icon}</Button>
  );

  const getToolbarOption = (isCollapsible: boolean) => {
    return (
      <>
        {toolbarGroups.map((group: any, index: number) => (
          <>
            {group.map((element: any) => {
              if (isSmallToolbar && element.isCollapsible && !isCollapsible)
                return;
              if (!element.isCollapsible && isCollapsible) return;

              switch (element.type) {
                case 'block':
                  return (
                    <BlockButton
                      editor={editor}
                      key={element.id}
                      {...element}
                    />
                  );
                case 'mark':
                  return (
                    <MarkButton editor={editor} key={element.id} {...element} />
                  );
                case 'dropdown':
                  return (
                    <ToolbarDropdown
                      editor={editor}
                      key={element.id}
                      {...element}
                    />
                  );
                case 'custom_dropdown':
                  return (
                    <CustomDropdown
                      editor={editor}
                      key={element.id}
                      {...element}
                    />
                  );
                case 'link':
                  return (
                    <LinkButton
                      key={element.id}
                      active={isBlockActive(editor, TYPES.LINK)}
                      editor={editor}
                    />
                  );
                case 'embed':
                  return (
                    <Embed
                      key={element.id}
                      format={element.format}
                      editor={editor}
                      imageUploadCallback={imageUploadCallback}
                    />
                  );
                case 'color-picker':
                  return (
                    <ColorPicker
                      key={element.id}
                      activeMark={activeMark}
                      format={element.format}
                      editor={editor}
                    />
                  );
                case 'table':
                  return <TableSelector key={element.id} editor={editor} />;
                default:
                  return null;
              }
            })}

            {(!isSmallToolbar ||
              (!group.reduce(
                (prev: boolean, cur: any) => prev && cur.isCollapsible,
                true
              ) &&
                !isCollapsible)) &&
              getDivider(index)}
          </>
        ))}

        {!isSmallToolbar && additionalToolbarActions && (
          <>
            <Divider />
            {AdditionalActions}
          </>
        )}
      </>
    );
  };

  const setFocus = (selection: any) => {
    if (selection)
      setTimeout(() => {
        if (Range.isCollapsed(selection)) {
          Transforms.select(editor, selection);
          ReactEditor.focus(editor);
        }
      }, 50);
  };

  const handleMoreClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSelection(editorSelection);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFocus(selection);
  };

  return (
    <div
      className={classnames(classes.toolbar, customToolbarClassName)}
      {...restProps}
    >
      {getToolbarOption(false)}

      {isSmallToolbar && (
        <>
          <Button active={anchorEl} onMouseDown={handleMoreClick}>
            <Icon icon="more" />
          </Button>

          <Popover
            PaperProps={{
              className: classes.popoverPaper,
            }}
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {getToolbarOption(true)}
            {isSmallToolbar && AdditionalActions}
          </Popover>
        </>
      )}
    </div>
  );
};

export default Toolbar;
