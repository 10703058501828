import { useMediaQuery } from '@material-ui/core';
import Interweave from 'interweave';
import React, { useEffect, useRef } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import { Modal } from '../../../../composites';
import MobileModal from '../../../MobileModal/MobileModal';
import Textarea from '../../../Textarea/Textarea';
import Icon from '../../common/Icon';
import { HtmlActionProps } from '../../RichTextEditorV2';

import { TYPES } from '../../utils/SlateUtilityFunctions';
import { useStyles } from './CodeToText.styles';

interface CodeToTextProps {
  showInput?: boolean;
  html?: string;
  action?: string;
  location?: any;
  handleCodeToText: Function;
  codeToTextTextareaProps?: any;
}

const CodeToText = (props: CodeToTextProps) => {
  const isDesktop = useMediaQuery('(min-device-width: 767px)');
  const classes = useStyles({ isDesktop });

  const {
    showInput,
    html,
    action,
    location,
    handleCodeToText,
    codeToTextTextareaProps,
  } = props;
  const codeToTextRef = useRef<any>();
  const wrapperRef = useRef<any>();

  const editor = useSlateStatic();

  const checkClick = (e) => {
    const clickedComponent = e.target;
    if (
      wrapperRef?.current?.contains(clickedComponent) &&
      !codeToTextRef?.current?.contains(clickedComponent)
    ) {
      let partialState: HtmlActionProps = {
        showInput: false,
      };
      if (html) {
        partialState.html = action === 'update' ? '' : html;
      }
      handleCodeToText(partialState);
    }
  };

  useEffect(() => {
    document.addEventListener('click', checkClick);
    return () => {
      document.removeEventListener('click', checkClick);
    };
  }, []);

  const codeOnChange = async (e: React.SyntheticEvent) => {
    // e.preventDefault();
    handleCodeToText({ html: e.target.value });
  };
  const addHtml = () => {
    if (html) {
      if (action === 'update') {
        Transforms.setNodes(
          editor,
          {
            html,
          },
          {
            at: location,
          }
        );
      } else {
        Transforms.insertNodes(
          editor,
          {
            type: TYPES.HTML_CODE,
            html: html,
            children: [{ text: '' }],
          },
          {
            select: true,
          }
        );
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{ text: '' }],
        });
      }
    }
    handleCodeToText({
      showInput: false,
      html: '',
    });
  };
  const clearHtml = () => {
    handleCodeToText({ html: '' });
  };

  const ModalContent = (
    <div className={classes.codeToText}>
      <Textarea
        label="HTML"
        placeholder="Write html here..."
        minHeight="300px"
        maxHeight="90px"
        minWidth="0"
        value={html}
        onChange={codeOnChange}
        {...codeToTextTextareaProps}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon icon={'arrowRight'} />
      </div>

      <div className={classes.textOutput}>
        <Interweave content={html} />
      </div>
    </div>
  );

  return isDesktop ? (
    <Modal
      dialogContentClassName={classes.dialogContentClassName}
      modalPaperClassName={classes.modalPaperClassName}
      title="Code to text"
      primaryBtnText="Done"
      secondaryBtnText="Clear"
      open={showInput}
      onClose={() => {
        handleCodeToText({
          showInput: false,
        });
      }}
      onPrimaryBtnClick={addHtml}
      onSecondaryBtnClick={clearHtml}
    >
      {ModalContent}
    </Modal>
  ) : (
    <MobileModal
      open={showInput}
      onClose={() => {
        handleCodeToText({
          showInput: false,
        });
      }}
      header="Code to text"
      primaryAction={{
        label: 'Done',
        onClick: addHtml,
      }}
      secondaryAction={{
        label: 'Clear',
        onClick: clearHtml,
      }}
    >
      <div className={classes.dialogContentClassName}>{ModalContent}</div>
    </MobileModal>
  );
};

export default CodeToText;
