import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';
import Chip from '../../Chip/Chip';
import MobileModal from '../../MobileModal/MobileModal';

import useStyles from '../styles';
import { IMultiSelectProps } from '../types';

const MultiSelect = ({
  value,
  onChange,
  placeholder,
  error,
  helperText,
  options,
  label,
  fullWidth,
  width,
  showSelectAll,
  hideDeleteAll,
  headerComponent,
  footerComponent,
  renderOption,
  selectAllContent,
  deselectAllContent,
  mobileModalTitle,
  classes: propClasses = {},
  renderSelectedValue,
  allOptionsSelectedLabel = 'All from the list',
}: IMultiSelectProps) => {
  const isDesktop = useMediaQuery('(min-width: 767px)');
  const [anchor, setAnchor] = useState(null);
  const offeringWrapRef = useRef(null);
  const [valueCache, setValueCache] = useState(value || []);

  useEffect(() => {
    setValueCache(value);
  }, [value]);

  const valueMap = useMemo(
    () =>
      options.reduce((acc: any, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [options]
  );

  const valueSelectionMap = useMemo(
    () =>
      valueCache.reduce((acc: any, curr: string) => {
        acc[curr] = true;
        return acc;
      }, {}),
    [valueCache]
  );

  const onOpen = useCallback(
    (event) => setAnchor(event.currentTarget),
    [setAnchor]
  );
  const onClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const handleClearValueCache = useCallback(() => {
    setValueCache(value);
  }, [value]);

  const handleClose = useCallback(() => {
    handleClearValueCache();
    onClose();
  }, [handleClearValueCache, onClose]);

  const onApply = useCallback(() => {
    onChange(valueCache);
    onClose();
  }, [onChange, onClose, valueCache]);

  const handleToggle = useCallback(
    (toggleItem, manipulateCache = true) => {
      return function () {
        let updatedValues;

        if (toggleItem === 'ALL') {
          updatedValues =
            valueCache.length === options.length
              ? []
              : options.map((item) => item.value);
        } else {
          const currentIndex = valueCache.indexOf(toggleItem);
          updatedValues = [...valueCache];

          if (currentIndex === -1) {
            updatedValues.push(toggleItem);
          } else {
            updatedValues.splice(currentIndex, 1);
          }
        }

        if (manipulateCache) setValueCache(updatedValues);
        else onChange(updatedValues);
      };
    },
    [valueCache, onChange, options]
  );

  const isAllSelected = valueCache.length === options.length;
  const isIndeterminate = valueCache.length < options.length;
  const isEmpty = !valueCache.length;

  const classes = useStyles({
    showPlaceholder: isEmpty,
    error,
    fullWidth,
    width: fullWidth ? '100%' : width,
    offeringWrapWidth: offeringWrapRef.current?.offsetWidth,
  });

  const content = (
    <div
      className={classnames(
        classes.contentWrap,
        classes.scroll,
        isDesktop && classes.desktopContent,
        propClasses.contentWrap
      )}
    >
      {headerComponent}

      {showSelectAll && !!options.length && (
        <div
          onClick={handleToggle('ALL')}
          className={classnames(
            classes.selectAll,
            propClasses.selectAllWrapper
          )}
        >
          <Checkbox
            size="large"
            checked={isAllSelected}
            indeterminate={isIndeterminate}
          />
          <span
            className={classnames(classes.font_14, propClasses.selectAllText)}
          >
            {isAllSelected ? deselectAllContent : selectAllContent}
          </span>
        </div>
      )}

      {options.map((option, index, arr) => {
        if (!renderOption)
          return <div className={propClasses.option}>{option.label}</div>;
        return renderOption({
          option,
          value,
          onChange: handleToggle(option.value),
          isLast: index === arr.length - 1,
          isSelected: Boolean(valueSelectionMap?.[option.value]),
          index,
        });
      })}

      {footerComponent}
    </div>
  );

  const _renderSelectedValue = () => {
    if (isEmpty) return placeholder;

    if (renderSelectedValue)
      return renderSelectedValue({
        selectedValue: value,
        selectedOption: valueMap[value],
      });

    if (showSelectAll && isAllSelected) return allOptionsSelectedLabel;

    return (
      <div className={classes.chips}>
        {valueCache.map((item: string) => (
          <Chip
            key={item}
            label={valueMap[item]?.label}
            chipIcon={
              <ClearRoundedIcon
                onMouseDown={(event) => event.stopPropagation()}
              />
            }
            chipClassName={classes.filterChip}
            onDelete={handleToggle(item, false)}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      ref={offeringWrapRef}
      className={classnames(classes.outerWrap, propClasses.wrapper)}
    >
      {label && (
        <div
          className={classnames(
            classes.formTitle,
            classes.mb_2,
            propClasses.label
          )}
        >
          {label}
        </div>
      )}

      <div
        className={classnames(classes.offeringDropWrap, propClasses.dropdown)}
        onClick={onOpen}
      >
        <div
          className={classnames(
            classes.selectedTitles,
            classes.multiSelectTitles
          )}
        >
          {_renderSelectedValue()}
        </div>
        {!hideDeleteAll && (
          <IconButton
            component="span"
            className={classes.deleteAllChipsBtn}
            onClick={(event) => {
              event.stopPropagation();
              if (onChange) onChange([]);
            }}
          >
            <CloseRoundedIcon className={classes.crossIcon} />
          </IconButton>
        )}
        <KeyboardArrowDownRoundedIcon className={classes.selectIcon} />
      </div>

      {helperText && (
        <div
          className={classnames(
            classes.helper,
            classes[error ? 'errorWrapper' : 'helperWrapper'],
            propClasses.helperTextWrapper
          )}
        >
          {error && (
            <WarningRoundedIcon
              preserveAspectRatio="none"
              viewBox="3 4 19.06 17.01"
              className={classnames(classes.errorIcon, propClasses.errorIcon)}
            />
          )}
          <span
            className={classnames(
              classes[error ? 'errorText' : 'helperText'],
              propClasses.helperText
            )}
          >
            {helperText}
          </span>
        </div>
      )}

      {isDesktop ? (
        <Popover
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          classes={{
            paper: classnames(
              classes.popover_paper,
              classes.scroll,
              propClasses.popoverPaper
            ),
          }}
        >
          {content}
          <Button
            fullWidth
            size="thin"
            onClick={onApply}
            buttonWrapperClassName={classes.popoverApplyBtn}
          >
            Apply
          </Button>
        </Popover>
      ) : (
        <MobileModal
          open={anchor}
          onClose={handleClose}
          header={mobileModalTitle}
          paperClassName={classnames(
            classes.drawerPaper,
            propClasses.mobileModalPaper
          )}
          primaryAction={{
            label: 'Apply',
            onClick: onApply,
          }}
        >
          {content}
        </MobileModal>
      )}
    </div>
  );
};

export default MultiSelect;
