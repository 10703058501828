import React, { useRef, useEffect, useState } from 'react';
import MaterialInput, {
  OutlinedInputProps as MaterialInputProps,
} from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaterialPopover from '@material-ui/core/Popover';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

import classnames from 'classnames';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import useStyles from './Input.styles';
import Dropdown, { Option } from '../Dropdown/Dropdown';

interface IInputPrefixProps {
  type: 'icon' | 'select';
  icon?: any;
  options?: Option[];
  value?: any;
  onChange?: any;
  outerWrapperClassName?: any;
  innerWrapperClassName?: any;
  width?: any;
  dropdownProps?: any;
}

export interface InputProps extends MaterialInputProps {
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  helperText?: string;
  size: 'thin' | 'small';
  wrapperClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  inputElementClassName?: string;
  helperTextClassName?: string;
  inputWrapperClassName?: string;
  errorIconClassName?: string;
  errorHelperTextClassName?: string;
  fullWidth?: boolean;
  hasPopover?: boolean;
  popoverContent?: any;
  popoverProps?: any;
  currencyPrefix?: IInputPrefixProps;
}

const Input: React.FC<InputProps> = ({
  label,
  helperText,
  error,
  iconPosition,
  icon,
  size = 'small',
  wrapperClassName,
  labelClassName,
  inputClassName,
  helperTextClassName,
  inputWrapperClassName,
  inputElementClassName,
  errorIconClassName,
  errorHelperTextClassName,
  classes: inputClasses,
  fullWidth,
  hasPopover,
  popoverContent,
  popoverProps,
  currencyPrefix,
  ...restProps
}) => {
  const classes = useStyles({
    fullWidth,
    showCurrency: currencyPrefix,
    currencyWrapWidth: currencyPrefix?.width,
    size,
  });
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  let additionalProps = { error };

  useEffect(() => {
    setAnchorEl(inputRef.current);
  }, []);

  if (icon && iconPosition) {
    additionalProps = {
      ...additionalProps,
      [`${iconPosition}Adornment`]: (
        <InputAdornment
          position={iconPosition}
          className={[
            iconPosition === 'start'
              ? classes.inputStartIcon
              : classes.inputEndIcon,
            inputWrapperClassName,
          ].join(' ')}
        >
          {icon}
        </InputAdornment>
      ),
    };
  }
  return (
    <div className={wrapperClassName}>
      {label ? (
        <div className={[classes.labelWrapper, labelClassName].join(' ')}>
          {label}
        </div>
      ) : null}

      <div
        className={classnames(
          classes.wrapper,
          currencyPrefix?.outerWrapperClassName
        )}
      >
        {currencyPrefix && (
          <div
            className={classnames(
              classes.currencyInnerWrapper,
              currencyPrefix?.innerWrapperClassName
            )}
          >
            {currencyPrefix.type === 'icon' && currencyPrefix.icon}

            {currencyPrefix.type === 'select' && (
              <Dropdown
                value={currencyPrefix.value}
                options={currencyPrefix.options}
                handleChange={currencyPrefix.onChange}
                size="small"
                IconComponent={ArrowDropDownRoundedIcon}
                formControlClassName={classes.formControlClassName}
                selectClassName={classes.selectClassName}
                inputBaseRootClassName={classes.inputBaseRootClassName}
                fullWidth
                {...(currencyPrefix.dropdownProps || {})}
              />
            )}
          </div>
        )}

        <MaterialInput
          ref={inputRef}
          classes={{
            root: classes.root,
            notchedOutline: error ? classes.error : classes.notchedOutline,
            input: classnames(classes.inputElement, inputElementClassName),
            ...inputClasses,
          }}
          className={classnames(
            classes.input,
            size && classes[size],
            inputClassName
          )}
          {...additionalProps}
          {...restProps}
        />
      </div>

      {helperText && (
        <div
          className={[
            classes.helper,
            classes[error ? 'errorWrapper' : 'helperWrapper'],
            helperTextClassName,
          ].join(' ')}
        >
          {error && (
            <WarningRoundedIcon
              preserveAspectRatio="none"
              viewBox="3 4 19.06 17.01"
              className={[classes.errorIcon, errorIconClassName].join(' ')}
            />
          )}
          <span
            className={[
              classes[error ? 'errorText' : 'helperText'],
              errorHelperTextClassName,
            ].join(' ')}
          >
            {helperText}
          </span>
        </div>
      )}

      <MaterialPopover
        anchorEl={anchorEl}
        open={hasPopover}
        PaperProps={{
          className: classes.popoverPaperRoot,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...popoverProps}
      >
        {popoverContent}
      </MaterialPopover>
    </div>
  );
};

export default withGenerateClassName(Input);
