import { Button, useMediaQuery } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { FC, Fragment } from 'react';
import Tooltip from '../../../../../Tooltip/Tooltip';
import { IVariableChip } from './VariableChip.interfaces';
import { useStyles } from './VariableChip.styles';

const VariableChip: FC<IVariableChip> = ({
  label,
  tooltip,
  buttonWrapperProps: inputButtonWrapperProps,
  ...buttonProps
}) => {
  const isDesktop = useMediaQuery('(min-device-width: 767px)');
  const styles = useStyles();
  const TooltipWrapper = tooltip && isDesktop ? Tooltip : Fragment;
  const InfoIconWrapper = tooltip ? Tooltip : Fragment;
  const WrapperComponent = isDesktop
    ? Fragment
    : ((({ children, className }) => (
        <div className={className}>{children}</div>
      )) as FC<{ className?: string }>);
  const buttonWrapperProps = tooltip
    ? {
        title: tooltip,
        color: 'primary',
        arrow: true,
        ...inputButtonWrapperProps,
      }
    : undefined;
  const wrapperProps = isDesktop
    ? undefined
    : { className: styles.wrapperRoot };

  return (
    <WrapperComponent {...wrapperProps}>
      <TooltipWrapper {...buttonWrapperProps}>
        <Button className={styles.root} {...buttonProps}>
          {label}
        </Button>
      </TooltipWrapper>

      {isDesktop ? null : (
        <InfoIconWrapper {...buttonWrapperProps}>
          <InfoOutlined className={styles.infoIcon} />
        </InfoIconWrapper>
      )}
    </WrapperComponent>
  );
};

export default VariableChip;
