import { makeStyles } from '@material-ui/core';

interface StyleProps {
  showBorder?: boolean;
  toolbarPosition?: 'top' | 'bottom';
  minHeight?: string;
  maxHeight?: string;
  padding?: string;
  isBoxDisabled: boolean;
}

export const useStyles = makeStyles(
  (theme: any) => ({
    root: {
      border: ({ showBorder }: StyleProps) =>
        showBorder ? '1.5px solid #CFCDC9' : '',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: ({ toolbarPosition }: StyleProps) =>
        toolbarPosition === 'top' ? 'column' : 'column-reverse',
    },
    editable: {
      minHeight: ({ minHeight }: StyleProps) => minHeight || '153px',
      maxHeight: ({ maxHeight }: StyleProps) => maxHeight || 'auto',
      padding: ({ padding }: StyleProps) => padding || '16px 16px 0 16px',
      overflow: ({ isBoxDisabled }: StyleProps) =>
        isBoxDisabled ? 'none' : 'auto',
    },
  }),
  { name: 'ComponentLibrary' }
);
