import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme: any) => ({
    popper: {},
    iconBtn: {
      padding: '4px',
      borderRadius: '5px',
      '&:hover': {
        background: theme?.palette?.secondary?.shade_200,
        border: 'none',
      },
      color: theme?.palette?.secondary?.shade_600,
    },
    resizeBtn: {
      position: 'absolute',
      top: 0,
      right: 0,
      background: theme?.palette?.secondary?.shade_600,
      color: theme?.palette?.basic?.white,
      padding: '0 4px 0 0',
    },
    resizeIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    controlsContainer: {
      borderRadius: '5px',
      boxShadow: '3px 3px 8px grey',
      background: theme?.palette?.basic?.white,
      padding: '6px',
      width: 'fit-content',
      margin: '0 auto',
      position: 'absolute',
      top: 0,
      zIndex: 1,
    },
    imageControls: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    active: {
      background: theme?.palette?.primary?.shade_200,
      color: theme?.palette?.primary?.main,
    },
    widthIcon: {
      position: 'relative',
      '& p': {
        position: 'absolute',
        fontSize: '8px',
      },
    },
    linkPopper: {
      borderRadius: '5px',
      boxShadow: '3px 3px 8px grey',
      background: theme?.palette?.basic?.white,
      padding: '8px',
    },
    popperCta: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '8px',
    },
    embed: {
      position: 'relative',
    },
    img: {
      width: '100%',
      height: '100%',
    },
    btn: {
      position: 'absolute',
      bottom: '-6px',
      right: 0,
    },
    redirectToLink: {
      display: 'flex',
      gap: '8px',
      flexDirection: 'column',
      marginTop: '8px',
    },
    linkCta: {
      display: 'flex',
      gap: '8px',
    },
    cancelCta: {
      color: '#524E47',
    },
    mobileModal: {
      padding: '16px',
    },
    linkPopoverPaper: {
      padding: '8px',
    },
    linkInputBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    input: {
      flex: 1,
    },
    centerImage: {
      left: '50% !important',
      translate: '-50% !important',
    },
    left: {},
    right: {
      left: 'auto !important',
      right: 0,
    },
    center: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    w100: {
      width: '100%',
    },
    pos_absolute: {
      position: 'absolute',
    },
    box_shadow: {
      boxShadow: '0 0 3px 3px lightgray',
    },
  }),
  { name: 'ComponentLibrary' }
);
