import { Editor, Transforms, Path, Range, Element } from 'slate';
import { TYPES } from './SlateUtilityFunctions';

export const insertLink = (editor: any, { url, showInNewTab }: any) => {
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    href: url,
    children: isCollapsed ? [{ text: url }] : [],
    target: showInNewTab ? '_blank' : '_self',
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const removeLink = (editor: any) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === TYPES.LINK,
  });
};
