import React from 'react';
import { ListsEditor } from '@prezly/slate-lists';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import { isBlockActive, toggleBlock } from '../../utils/SlateUtilityFunctions';

interface BlockButtonProps {
  format: string;
  editor: any;
}

export const BlockButton: React.FC<BlockButtonProps> = ({
  format,
  editor,
  ...restProps
}) => {
  return (
    <Button
      active={isBlockActive(editor, format)}
      format={format}
      onMouseDown={(e) => {
        e.preventDefault();

        if (format === 'indent-right') {
          ListsEditor.increaseDepth(editor);
          return;
        }
        if (format === 'indent-left') {
          ListsEditor.decreaseDepth(editor);
          return;
        }

        toggleBlock(editor, format);
      }}
      {...restProps}
    >
      <Icon icon={format} />
    </Button>
  );
};
