import { makeStyles } from '@material-ui/core';

interface StyleProps {
  toolbarPosition: 'top' | 'bottom';
  isDesktop?: boolean;
  showBorder?: boolean;
  showMobileToolbar?: boolean;
  minWidth?: string;
}

export const useStyles = makeStyles(
  (theme: any) => ({
    formControlClassName: {
      '& svg': {
        color: theme?.palette?.secondary?.shade_600,
      },
    },
    inputBaseRootClassName: {
      background: theme?.palette?.secondary?.shade_50,
      border: 'none',
      '&.Mui-focused': {
        border: 'none',
      },
    },
    selectClassName: {
      background: theme?.palette?.secondary?.shade_50,
      color: theme?.palette?.secondary?.shade_600,
      marginRight: '8px',

      '&:focus': {
        background: theme?.palette?.secondary?.shade_50,
      },
    },
    toolbar: {
      display: 'flex',
      background: '#F3F2F1',
      borderRadius: ({ toolbarPosition, showBorder }: StyleProps) =>
        showBorder
          ? toolbarPosition === 'top'
            ? '5px 5px 0px 0px'
            : '0px 0px 5px 5px'
          : '',
      borderBottom: '1.5px solid #E7E6E4',
      alignItems: 'center',
      gap: '4px',
      flexWrap: 'wrap',
      padding: '4px',
    },
    bottomBar: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      right: '0',
      width: '100%',
      zIndex: 10,
    },
    popoverPaper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '6px',
      maxWidth: '128px',
      flexWrap: 'wrap',
      rowGap: '6px',
      zIndex: 9999,
    },
    complexDropdown: {
      padding: '4px',
      cursor: 'pointer',
      boxSizing: 'border-box',
      overflow: 'hidden',
      fontSize: '14px',
      borderRadius: '5px',
      background: theme?.palette?.secondary?.shade_50,
      minWidth: ({ minWidth }: StyleProps) => minWidth,
      paddingLeft: '8px',
    },
    dropdownLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      justifyContent: 'space-between',
      color: theme?.palette?.secondary?.shade_600,
    },
    selectedOption: {
      background: theme?.palette?.primary?.main,
      color: `${theme?.palette?.basic?.white} !important`,
      '& svg': {
        color: theme?.palette?.basic?.white,
      },
    },
    dropdownOpen: {
      transform: 'rotate(180deg)',
    },
    customDropdownOption: {
      fontSize: '14px',
      minHeight: '26px',
      boxSizing: 'border-box',
      cursor: 'pointer',
      color: theme?.palette?.secondary?.main,
      display: 'flex',
      gap: '8.5px',
      alignItems: 'center',
      whiteSpace: 'break-spaces',
      padding: '5px 9px',
      '&:hover:not(.Mui-disabled)': {
        background: theme?.palette?.secondary?.shade_100,
        color: `${theme?.palette?.secondary?.main} !important`,
        '& svg': {
          color: theme?.palette?.secondary?.main,
        },
      },
    },
    variableButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '13px 14px',
      gap: '10px',
      cursor: 'pointer',
    },
    variableButtons: {
      fontFamily: 'Helvetica Neue',
      height: '20px',
      background: theme?.palette?.persian_blue?.shade_400,
      borderRadius: '3px',
      letterSpacing: '0.02em',
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      padding: '2px 6px',
      color: theme?.palette?.basic?.white,
      cursor: 'pointer',
      fontSize: '12px',
    },
    secondaryButton: {
      // background: '#CC5C98',
      background: '#00B779',
    },
    tertiaryButton: {
      background: '#00B779',
    },
    variableButtonsPopoverPaper: {
      overflow: 'hidden',
      border: '1px solid #CCCCCC',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.12)',
      borderRadius: '8px',
    },
    closeIcon: {
      color: theme?.palette?.secondary?.shade_500,
      fontSize: '16px',
      cursor: 'pointer',
    },
    dropdownLabelPersonalize: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      justifyContent: 'space-between',
      color: theme?.palette?.persian_blue?.shade_400,
      fontFamily: 'Helvetica Neue',
      fontSize: '14px',
    },
  }),
  { name: 'ComponentLibrary' }
);
