import { FC } from 'react';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';

import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface StyleProps {
  anchor: 'top' | 'right' | 'bottom' | 'left';
}

const drawerBorder = {
  top: '0 0 12px 12px',
  right: '12px 0 0 12px',
  bottom: '12px 12px 0 0',
  left: '0 12px 12px 0',
};

const useStyles = makeStyles(
  (globaltheme: any) => ({
    backDrop: {
      background: 'rgba(14, 13, 12, 0.2)',
    },
    drawerRoot: {},
    drawerPaper: {
      borderRadius: ({ anchor }: StyleProps) => drawerBorder[anchor],
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
      fontFamily: globaltheme?.typography?.fontFamily,
    },
  }),
  { name: 'ComponentLibrary' }
);

export interface IMobilePopover extends DrawerProps {
  drawerClassName?: string;
  paperClassName?: string;
  backDropClassName?: string;
}

const MobilePopover: FC<IMobilePopover> = ({
  anchor = 'bottom',
  open,
  onClose,
  children,
  drawerClassName,
  paperClassName,
  backDropClassName,
  BackdropProps,
  classes: drawerClasses,
  ...restProps
}) => {
  const classes = useStyles({ anchor });
  const { classes: backDropClasses = {}, ...restBackDropProps } =
    BackdropProps || {};
  return (
    <Drawer
      anchor={anchor}
      classes={{
        root: classnames(classes.drawerRoot, drawerClassName),
        paper: classnames(classes.drawerPaper, paperClassName),
        ...drawerClasses,
      }}
      BackdropProps={{
        timeout: 500,
        classes: {
          root: classnames(classes.backDrop, backDropClassName),
          ...backDropClasses,
        },
        ...restBackDropProps,
      }}
      closeAfterTransition
      open={open}
      onClose={onClose}
      {...restProps}
    >
      {children}
    </Drawer>
  );
};

export default withGenerateClassName(MobilePopover);
