import { Popover, useMediaQuery } from '@material-ui/core';
import { FC } from 'react';
import MobileModal from '../../../MobileModal/MobileModal';
import VariableChip from './modules/VariableChip/VariableChip';
import { IVariableChip } from './modules/VariableChip/VariableChip.interfaces';
import { IRTEVariablesModal } from './RTEVariablesModal.interfaces';
import { useStyles } from './RTEVariablesModal.styles';

const RTEVariablesModal: FC<IRTEVariablesModal> = ({
  chips,
  onClickVariable,
  classes = {},
  variableChipProps,
  ...restProps
}) => {
  const isDesktop = useMediaQuery('(min-device-width: 767px)');
  const styles = useStyles();
  const ViewComponent = isDesktop ? Popover : MobileModal;
  const viewComponentProps = isDesktop
    ? undefined
    : { header: 'Personalise', customFooter: <></>, anchor: 'bottom' };
  const { paper, rootClassName } = classes;

  const handleClickVariable = (
    e: React.SyntheticEvent,
    chip: IVariableChip
  ) => {
    if (onClickVariable) onClickVariable(chip);
  };

  return (
    <ViewComponent
      classes={
        isDesktop
          ? {
              ...classes,
              paper: [isDesktop ? styles.viewRoot : '', paper].join(' '),
            }
          : classes
      }
      disablePortal
      {...viewComponentProps}
      {...restProps}
    >
      <div
        className={[
          isDesktop ? styles.root : styles.mobileRoot,
          rootClassName,
        ].join(' ')}
      >
        {chips?.map((chip) => (
          <VariableChip
            key={chip.label}
            onClick={(e) => handleClickVariable(e, chip)}
            {...variableChipProps}
            {...chip}
          />
        ))}
      </div>
    </ViewComponent>
  );
};

export default RTEVariablesModal;
