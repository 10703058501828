import { Transforms } from 'slate';

import { createParagraph } from './paragraph';
export const createEmbedNode = (type, { url, alt, ...rest }) => ({
  type,
  alt,
  url,
  children: [{ text: '' }],
  ...rest,
});

export const insertEmbed = (editor: any, embedData: any, format: string) => {
  const { url } = embedData;
  if (!url) return;
  const embed = createEmbedNode(format, embedData);

  Transforms.insertNodes(editor, embed, { select: true });
  Transforms.insertNodes(editor, createParagraph(''));
};
