import React from 'react';

import {
  FormatBold as BoldIcon,
  FormatItalic as ItalicIcon,
  FormatUnderlined as UnderlineIcon,
  StrikethroughS,
  FormatAlignCenter as AlignCenterIcon,
  FormatAlignJustify as AlignJustifyIcon,
  FormatAlignLeft as AlignLeftIcon,
  FormatAlignRight as AlignRightIcon,
  FormatListBulleted as BulletedListIcon,
  FormatListNumbered as NumberedListIcon,
  Link,
  Image,
  VideoLibrary,
  TableChart,
  Delete,
  CloudUpload,
  Height,
  Code,
  FormatColorText,
  FormatColorFill,
  Check,
  BorderLeft,
  BorderRight,
  BorderBottom,
  BorderTop,
  ArrowForward,
  Add,
  FormatIndentDecrease,
  FormatIndentIncrease,
  MoreHoriz,
} from '@material-ui/icons';

const iconList = {
  bold: <BoldIcon />,
  italic: <ItalicIcon />,
  strikethrough: <StrikethroughS />,
  underline: <UnderlineIcon />,
  color: <FormatColorText />,
  bgColor: <FormatColorFill />,
  alignLeft: <AlignLeftIcon />,
  alignCenter: <AlignCenterIcon />,
  alignRight: <AlignRightIcon />,
  'ordered-list': <NumberedListIcon />,
  'unordered-list': <BulletedListIcon />,
  'indent-right': <FormatIndentIncrease />,
  'indent-left': <FormatIndentDecrease />,
  link: <Link />,
  image: <Image />,
  video: <VideoLibrary />,
  table: <TableChart />,
  insertRowBelow: <BorderBottom />,
  insertColumnRight: <BorderRight />,
  insertColumnLeft: <BorderLeft />,
  insertRowAbove: <BorderTop />,
  trashCan: <Delete />,
  upload: <CloudUpload />,
  resize: <Height />,
  insertHtml: <Code />,
  check: <Check />,
  arrowRight: <ArrowForward />,
  add: <Add />,
  more: <MoreHoriz />,
};

interface IconProps {
  icon: string;
}

const Icon = (props: IconProps) => {
  const { icon } = props;
  return iconList[icon] || icon;
};

export default Icon;
