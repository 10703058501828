import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FC, useEffect, useState } from 'react';
import { CompletedStep, ProgressProps } from './Progress';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globaltheme: any) => ({
    stepperRoot: {
      padding: 0,
      width: '100%',
    },
    label: {
      fontSize: '14px',
      paddingRight: '4px',
    },
    textBlack: {
      fill: globaltheme?.palette?.basic?.black,
    },
    textWhite: {
      fill: globaltheme?.palette?.basic?.white,
    },
    stepIconPropsRoot: {
      visibility: 'hidden',
      color: 'white',
      fontWeight: 400,
      fontSize: '14px',
      border: '1.5px solid black',
      boxSizing: 'border-box',
      borderRadius: '50%',
      marginRight: '-5px',
    },
    stepIconCompleted: {
      color: '#00B77A !important',
      border: 'none',
      visibility: 'visible',
    },
    stepIconActive: {
      border: 'none',
      color: '#493AB1 !important',
    },
    stepRoot: {
      paddingLeft: '11px',
      paddingRight: 0,
    },
    iconContainer: {
      paddingRight: 0,
    },
    iconContainerWithPadding: {
      paddingRight: 0,
    },
  }),
  { name: 'ComponentLibrary' }
);

const Connector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#00B779',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#00B779',
    },
  },
  line: {
    height: '6px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'rgba(73, 58, 177, 0.5);',
  },
})(StepConnector);

const Dashed: FC<Omit<ProgressProps, 'variant' | 'options'>> = ({
  stepsCount,
  activeStep,
  stepperClassName,
  stepClassName,
  stepLabelClassName,
}) => {
  const classes = useStyles();

  return (
    <Stepper
      className={[classes.stepperRoot, stepperClassName].join(' ')}
      activeStep={activeStep <= stepsCount ? activeStep : stepsCount}
      connector={<Connector />}
    >
      {[...Array(stepsCount + 1)].map((step, index) => {
        return (
          <Step
            key={index}
            className={classnames(classes.stepRoot, stepClassName)}
          >
            <StepLabel
              classes={{
                root: stepLabelClassName,
                label: classes.label,
                iconContainer:
                  activeStep === index
                    ? classes.iconContainerWithPadding
                    : classes.iconContainer,
              }}
              StepIconProps={{
                classes: {
                  root: classes.stepIconPropsRoot,
                  text:
                    activeStep === index
                      ? classes.textWhite
                      : classes.textBlack,
                  completed: classes.stepIconCompleted,
                  active: classes.stepIconActive,
                },
              }}
            />
          </Step>
        );
      })}
    </Stepper>
  );
};

export default withGenerateClassName(Dashed);
