import { isEmpty } from '../../../utils/validation';
import { GOOGLE_DOC_REGEX, HTML_COMMENT_REGEX } from './regex';
import { deserializer } from './utils/deserializer';
import { serializer } from './utils/serializer';

interface EditorUtils {
  getEditorStateFromHtml: Function;
  getHtmlFromEditorState: Function;
  emptyHtml: string;
  emptyHtmlWithListStyle: string;
  emptyEditorState: any[];
  isEmpty: (editorState: any) => boolean;
  isEmptyHtmlFromString: (
    htmlString: string,
    saveHTMLTagArray?: string[]
  ) => boolean;
}

const emptyHtml = '<p></p>';
const emptyHtmlWithListStyle = '<p style="margin:0;min-height:18px"></p>'; // needed as withList plugin library having side affects

const isEmptyRTEString = (editorState: any) => {
  if (!editorState) return true;

  const editorStateInHtml =
    typeof editorState === 'string' ? editorState : serializer(editorState);

  return (
    editorStateInHtml === emptyHtml ||
    editorStateInHtml === emptyHtmlWithListStyle
  );
};

/**
 * @function isEmptyHtmlFromString - This function checks if an HTML string is empty or not.
 * @param {string} htmlString - The HTML string to check.
 * @param {string[]} [saveHTMLTagArray=[]] - An optional array of HTML tags to preserve during the check.
 * @description If the HTML string contains any of these tags (like ['img', 'table']) present in 'saveHTMLTagArray',
 * it considers an HTML string as non-empty the function returns false.
 * If not, the function strips the HTML string of all tags, spaces, line breaks,
 * and other whitespace characters, and checks if the resulting string is empty. If the stripped string is empty returns true,
 * @returns {boolean} - Returns true if the HTML string is considered empty, and false otherwise.
 */
function isEmptyHtmlFromString(
  htmlString: string,
  saveHTMLTagArray?: string[]
) {
  if (isEmpty(htmlString)) return true;
  const htmlTagArr = ['img', ...(saveHTMLTagArray || [])]; // array of elemets which wants to save
  // Check if the HTML string contains an tag
  const isContains = htmlTagArr.some((element) =>
    new RegExp(`<${element}[^>]*>`, 'i').test(htmlString)
  );
  // If the string contains an tag, it's not empty
  if (isContains) {
    return false;
  }
  // Remove all HTML tags, spaces, line breaks, etc.
  const strippedString = htmlString
    .replace(/<[^>]*>/g, '')
    .replace(/\s|&nbsp;/g, '');
  // Check if the resulting string is empty
  return isEmpty(strippedString);
}

const RichTextEditorV2Utils: EditorUtils = {
  getEditorStateFromHtml: deserializer,
  getHtmlFromEditorState: serializer,
  emptyHtml,
  emptyHtmlWithListStyle,
  emptyEditorState: [{ type: 'paragaph', children: [{ text: '' }] }],
  isEmpty: isEmptyRTEString,
  isEmptyHtmlFromString,
};

export const allowedStylesOnPaste = [
  'font-size',
  'font-family',
  'color',
  'line-height',
  'font-weight',
  'text-align',
];

/**
 * Check if an HTML string contains comments.
 * @param {string} htmlString - The HTML string to check for comments.
 * @returns {boolean} - True if comments are found, false otherwise.
 */
export const checkHtmlContainsComments = (htmlString: string): boolean => {
  // Check if the HTML string contains comments
  return htmlString.match(HTML_COMMENT_REGEX) !== null;
};

/**
 * Check if the given data is copied from Google Docs.
 * @param {string} htmlString - The HTML string to check for Google Docs formatting.
 * @returns {boolean} - True if Google Docs formatting is detected, false otherwise.
 */
export const isCopyFromDoc = (htmlString: string): boolean => {
  // Check if the copying is from Google Docs
  return htmlString.match(GOOGLE_DOC_REGEX) !== null;
};

export default RichTextEditorV2Utils;
