import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme: any) => ({
    dialogTitleRoot: {
      padding: '0',
    },
    dialogHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: theme?.palette?.basic?.white,
      color: theme?.palette?.secondary?.main,
      padding: '18px 16px',
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 700,
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dialogCrossIconBtn: {
      '&:hover': {
        background: theme?.palette?.basic?.white,
      },
      padding: '0',
    },
    dialogCrossIcon: {
      width: '18.94px',
      height: '18.94px',
      color: theme?.palette?.secondary?.main,
    },
    dialogContentRoot: {
      padding: '0',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: theme?.palette?.basic?.white,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme?.palette?.secondary?.shade_300,
        borderRadius: '5px',
      },
    },
    dialogActionsRoot: {
      padding: '0',
      display: 'block',
    },
    dialogFooter: {
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '18.5px 16px 18px 16px',
      position: 'sticky',
      bottom: 0,
      background: theme?.palette?.basic?.white,
    },
    footerBtn: {
      padding: '0 24px',
    },
    secondaryBtn: {
      color: theme?.palette?.secondary?.shade_700,
    },
  }),
  { name: 'ComponentLibrary' }
);
