import React from 'react';
import { makeStyles } from '@material-ui/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import Popover from '@material-ui/core/Popover';
import MobilePopover from '../../../MobilePopover/MobilePopover';

const useStyles = makeStyles(
  () => ({
    popoverPaper: {
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
    },
  }),
  { name: 'ComponentLibrary' }
);

type MuiBreakPoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface IPopup {
  open: boolean;
  onClose: () => void;
  width: MuiBreakPoints;
  popoverAnchorEl: null | Element | ((element: Element) => Element);
}

const Popup: React.FC<IPopup> = ({
  open,
  onClose,
  width,
  popoverAnchorEl,
  children,
}) => {
  const isDesktopView = isWidthUp('sm', width);
  const classes = useStyles();

  return isDesktopView ? (
    <Popover
      anchorEl={popoverAnchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        paper: classes.popoverPaper,
      }}
    >
      {children}
    </Popover>
  ) : (
    <MobilePopover anchor="bottom" open={open} onClose={onClose}>
      {children}
    </MobilePopover>
  );
};

export default withWidth()(Popup);
