import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import AlternateProgressLabelled from './AlternateProgressLabelled';
import Dashed from './Dashed';
import Labelled from './Labelled';
import ProgressBar from './ProgressBar';
import withGenerateClassName from '../../../themes/withGenerateClassName';

export interface CompletedStep {
  [key: number]: boolean;
}

export interface Option {
  title: string;
  description: string;
  completed: boolean;
  disabled: boolean;
}

export interface ProgressProps {
  mobile?: boolean;
  variant: 'labelled' | 'alternate' | 'dashed' | 'progress-bar';
  hasDescription?: boolean;
  connector?: 'line' | 'arrow';
  stepIconSize?: 'medium' | 'large';
  options: Option[];
  activeStep: number;
  onClick?: (e: React.SyntheticEvent, step: number) => void;
  stepsCount: number;
  progressClassName?: any;
  stepperClassName?: any;
  stepClassName?: any;
  stepContentClassName?: any;
  stepLabelClassName?: any;
  descriptionClassName?: any;
  hideProgress?: boolean;
  classes?: any;
}

const Progress: FC<ProgressProps> = ({
  variant,
  mobile = false,
  hasDescription = false,
  connector = 'line',
  stepIconSize = 'medium',
  options,
  activeStep,
  onClick,
  stepsCount,
  progressClassName,
  hideProgress,
  ...restProps
}) => {
  switch (variant) {
    case 'labelled':
      return (
        <Labelled
          mobile={mobile}
          hasDescription={hasDescription}
          connector={connector}
          stepIconSize={stepIconSize}
          options={options}
          activeStep={activeStep}
          onClick={onClick}
          {...restProps}
        />
      );
    case 'alternate':
      return (
        <AlternateProgressLabelled
          activeStep={activeStep}
          options={options}
          onClick={onClick}
          hideProgress={hideProgress}
          {...restProps}
        />
      );
    case 'dashed':
      return (
        <Dashed
          stepsCount={stepsCount}
          activeStep={activeStep}
          {...restProps}
        />
      );
    case 'progress-bar':
      return (
        <ProgressBar
          stepsCount={stepsCount}
          activeStep={activeStep}
          progressClassName={progressClassName}
          {...restProps}
        />
      );
    default:
      return null;
  }
};

export default withGenerateClassName(Progress);
