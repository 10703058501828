import { useRef, useState } from 'react';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import { isBlockActive, TYPES } from '../../utils/SlateUtilityFunctions.js';
import { Editor, Range, Transforms } from 'slate';
import Popup from './Popup.jsx';
import LinkForm from './LinkForm.jsx';
import { insertLink, removeLink } from '../../utils/link';
import isUrl from 'is-url';

interface LinkButtonProps {
  editor?: any;
  active?: boolean;
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { editor, active } = props;

  const linkInputRef = useRef(null);

  const [showInput, setShowInput] = useState(false);
  const [url, setUrl] = useState('');
  const [selection, setSelection] = useState();
  const [hasUrl, setHasUrl] = useState(false);

  const { selection: editorSelection } = editor;

  const toggleLink = () => {
    const isCollapsed = Range.isCollapsed(editorSelection);

    if (isCollapsed) return;

    setSelection(editor.selection);

    let url = '',
      tooltip = '';
    const { selection } = editor;
    if (!!selection) {
      const [parentNode] = Editor.parent(editor, selection.focus?.path);
      if (parentNode.type === TYPES.LINK) {
        url = parentNode.href;
        tooltip = parentNode.children[0]?.text || '';
      }
    }

    setHasUrl(!!url);
    setUrl(url);

    setShowInput((prev) => !prev);
  };

  const onUrlChange = (event: any) => setUrl(event.target.value);

  const onClose = () => {
    setShowInput(false);
  };

  const addLink = () => {
    if (!url) return;

    if (isUrl) Transforms.select(editor, selection);
    insertLink(editor, { url, showInNewTab: true });
    setUrl('');
    setShowInput((prev) => !prev);
  };

  const onRemoveLink = () => {
    removeLink(editor);
    setShowInput(false);
  };

  return (
    <div ref={linkInputRef} className="popup-wrapper">
      <Button
        className={showInput ? 'clicked' : ''}
        active={active || isBlockActive(editor, TYPES.LINK)}
        format={TYPES.LINK}
        onClick={toggleLink}
      >
        <Icon icon={TYPES.LINK} />
      </Button>

      <Popup
        open={showInput}
        onClose={onClose}
        popoverAnchorEl={linkInputRef.current}
      >
        <LinkForm
          urlValue={url}
          onUrlChange={onUrlChange}
          onAddLink={addLink}
          onRemoveLink={onRemoveLink}
          onClose={onClose}
          linkError={
            url && !isUrl(url) ? { error: true, helperText: 'Invalid URL' } : {}
          }
          hasUrl={hasUrl}
        />
      </Popup>
    </div>
  );
};

export default LinkButton;
