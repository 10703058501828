import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    viewRoot: {
      background: 'none',
      boxShadow: 'none',
    },
    root: {
      padding: 12,
      display: 'flex',
      flexWrap: 'wrap',
      gap: 12,
      minWidth: 308,
      maxWidth: 308,
      boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: 5,
      border: '0.5px solid #E7E6E4',
      marginBottom: 4,
      background: 'white',
    },
    mobileRoot: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      padding: 16,
    },
  },
  {
    name: 'RTEVariablesModal',
  }
);
