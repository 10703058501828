// import { FC } from "react";
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Button from '../../../../Button/Button';
import Input from '../../../../Input/Input';

import classnames from 'classnames';
import { CircularProgress } from '@material-ui/core';
interface StyleProps {
  paddedForMobile?: boolean;
  footerBtnsPosition: 'space-between' | 'flex-end';
}

const useStyles = makeStyles(
  (theme: any) => ({
    container: {
      width: ({ paddedForMobile }: StyleProps) =>
        paddedForMobile ? '' : '400px',
    },
    header: {
      color: theme?.palette?.secondary?.main,
      padding: ({ paddedForMobile }: StyleProps) =>
        `0px ${paddedForMobile ? '16px' : '24px'}`,
      fontSize: '18px',
      height: '59.5px',
      boxSizing: 'border-box',
      fontWeight: 700,
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerCloseIconBtn: {
      '&:hover': {
        background: theme?.palette?.basic?.white,
      },
      padding: '0',
    },
    headerCloseIcon: {
      width: '18.94px',
      height: '18.94px',
    },
    content: {
      padding: ({ paddedForMobile }: StyleProps) =>
        paddedForMobile ? '20.5px 16px 46px 16px' : '20.5px 24px 20px 24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    uploadBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
      '& svg': {
        color: theme?.palette?.primary?.main,
      },
      '&:hover svg': {
        color: theme?.palette?.basic?.white,
      },
    },
    fileInput: {
      display: 'none',
    },
    footer: {
      height: '80px',
      boxSizing: 'border-box',
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      justifyContent: ({ footerBtnsPosition }: StyleProps) =>
        footerBtnsPosition,
      alignItems: 'center',
      gap: '16px',
      padding: ({ paddedForMobile }: StyleProps) =>
        `0px ${paddedForMobile ? '16px' : '24px'}`,
    },
    footerBtn: {
      padding: '11px 24px',
    },
    secondaryBtn: {
      color: '#524E47',
    },
    loadingState: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    uploadLabel: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'ComponentLibrary' }
);

interface LinkError {
  error?: boolean;
  helperText?: string;
}

interface ImageFormProps {
  onSave: () => void;
  onFileUpload: any;
  imgSrcValue: string;
  onImgSrcValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  paddedForMobile?: boolean;
  footerBtnsPosition: 'space-between' | 'flex-end';
  altValue?: string;
  onAltValueChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  linkError?: LinkError;
  loading?: boolean;
}

const LoadingState = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loadingState}>
      <CircularProgress />
      <span>Uploading image...</span>
    </Box>
  );
};

const ImageForm = ({
  imgSrcValue,
  onImgSrcValueChange,
  altValue,
  onAltValueChange,
  onClose,
  onFileUpload,
  onSave,
  paddedForMobile,
  footerBtnsPosition,
  linkError,
  loading,
}: ImageFormProps) => {
  const classes = useStyles({ paddedForMobile, footerBtnsPosition });

  const onCancel = () => {
    const event = { target: { value: '' } } as any;
    onImgSrcValueChange(event);
    onClose();
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        Insert Image
        <IconButton
          disableRipple
          disableFocusRipple
          component="span"
          onClick={onClose}
          className={classes.headerCloseIconBtn}
        >
          <CloseRoundedIcon
            viewBox="3 4 19.06 17.01"
            className={classes.headerCloseIcon}
          />
        </IconButton>
      </Box>
      <Box className={classes.content}>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <Box>
              <input
                accept="image/*"
                className={classes.fileInput}
                id="contained-button-file"
                multiple
                type="file"
                onChange={onFileUpload}
              />
              <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                <Button
                  size="small"
                  color="secondary"
                  component="span"
                  fullWidth
                  className={classes.uploadBtn}
                >
                  <div className={classes.uploadLabel}>
                    <AddRoundedIcon />
                    <span>Upload image</span>
                  </div>
                </Button>
              </label>
            </Box>
            <Input
              label="Or Paste image URL*"
              placeholder="https://"
              size="small"
              value={imgSrcValue}
              onChange={onImgSrcValueChange}
              fullWidth
              error={linkError?.error}
              helperText={linkError?.helperText}
            />
            <Input
              label="Alternate text"
              placeholder="Enter alternative text..."
              helperText="Specifies an alternate text for an image, if the image cannot be displayed."
              size="small"
              value={altValue}
              onChange={onAltValueChange}
              fullWidth
            />
          </>
        )}
      </Box>

      <Box className={classes.footer}>
        <Button
          disabled={loading}
          onClick={onCancel}
          color="subtleSecondary"
          variant="text"
          size="medium"
          className={classnames(classes.footerBtn, classes.secondaryBtn)}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          color="primary"
          size="medium"
          onClick={onSave}
          disabled={!imgSrcValue || linkError?.error}
          className={classes.footerBtn}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ImageForm;
