import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import withEmbeds from './withEmbeds';
import withLinks from './withLinks';
import withTable from './withTable';
import { withListsReact } from '@prezly/slate-lists';
import { withListsPlugin } from './withLists';
import { withHtml } from './withHtml';

const plugins = [
  withListsReact,
  withListsPlugin,
  withEmbeds,
  withTable,
  withLinks,
  withReact,
  withHistory,
  withHtml,
];

export const createSlateEditor = () => {
  return plugins.reduce((result, plugin) => plugin(result), createEditor());
};
