import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface IStyleProps {
  width: string;
  height: string;
  borderRadius: string;
}

const useStyles = makeStyles(
  (theme) => ({
    wrapper_shimmer: ({ width, height, borderRadius }: IStyleProps) => ({
      width: `${width} !important`,
      maxWidth: `${width} !important`,
      height,
      maxHeight: height,
      borderRadius,
      overflow: 'hidden',
      animation: 'fullView 0.5s forwards linear',
    }),
    shimmer: {
      width: '100%',
      height: '100%',
      animation: '$shimmer 2s infinite',
      // TODO: update colors after design team deliver
      background:
        'linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)',
      backgroundSize: '1000px 100%',
    },
    '@keyframes shimmer': {
      '0%': {
        backgroundPosition: '-1000px 0',
      },
      '100%': {
        backgroundPosition: '1000px 0',
      },
    },
  }),
  { name: 'ComponentLibrary' }
);

interface IShimmerProps {
  width: string;
  height: string;
  borderRadius: string;
  wrapper_classname?: string;
  shimmer_classname?: string;
}

const Shimmer = ({
  wrapper_classname,
  shimmer_classname,
  width,
  height,
  borderRadius,
}: IShimmerProps) => {
  const classes = useStyles({
    width,
    height,
    borderRadius,
  });

  return (
    <div className={classnames(classes.wrapper_shimmer, wrapper_classname)}>
      <div className={classnames(classes.shimmer, shimmer_classname)} />
    </div>
  );
};

export default withGenerateClassName(Shimmer);
