import { makeStyles } from '@material-ui/core/styles';

interface IStyleProps {
  showPlaceholder?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  width?: any;
  offeringWrapWidth?: any;
}

const useStyles = makeStyles(
  (theme) => ({
    mt: {
      marginTop: '18px',
    },
    mb: {
      marginBottom: '18px',
    },
    mb_12: {
      marginBottom: '12px',
    },
    gap_24: {
      gap: '24px',
    },
    gap_12: {
      gap: '12px',
    },
    p_0: {
      padding: 0,
    },
    mb_2: {
      marginBottom: '8px',
    },
    borderBottom: {
      borderBottom: `1.5px solid ${theme.palette.secondary.shade_100}`,
    },
    formTitle: {
      fontSize: '14px',
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
    outerWrap: {
      width: ({ width }: IStyleProps) => width,
    },
    offeringDropWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      lineHeight: '19px',
      boxSizing: 'border-box',
      border: ({ error }: IStyleProps) =>
        `1.5px solid ${
          error
            ? theme?.palette?.basic?.danger_red
            : theme.palette.secondary.shade_200
        }`,
      borderRadius: '5px',
      padding: '8.5px 6px 9.5px 12px',
      fontSize: '16px',
      width: ({ width }: IStyleProps) => width,
      cursor: 'pointer',
    },
    selectedTitles: {
      flexGrow: 1,
      color: ({ showPlaceholder }: IStyleProps) =>
        showPlaceholder
          ? theme.palette.basic.light_gray
          : theme.palette.secondary.main,
    },
    multiSelectTitles: {
      width: 'calc(100% - 80px)',
      paddingRight: '12px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '5px',
      width: '100%',
    },
    filterChip: {
      maxWidth: '100%',
      height: 'auto !important',
    },
    selectIcon: {
      height: '19px',
      color: theme.palette.basic.light_gray,
    },
    popover_paper: {
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      width: ({ offeringWrapWidth }: IStyleProps) => offeringWrapWidth,
      maxHeight: '400px',
    },
    popoverApplyBtn: {
      position: 'sticky',
      bottom: 0,
    },
    scroll: {
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.secondary.shade_100,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.secondary.shade_400,
        borderRadius: '2px',
      },
    },
    contentWrap: {
      overflowY: 'auto',
    },
    desktopContent: {
      height: '300px',
    },
    yelloish: {
      color: '#FFAA00',
    },
    loaderWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '64px',
    },
    loadingImage: {
      width: '32px',
      height: '32px',
    },
    offering: {
      borderTop: `1.5px solid ${theme.palette.secondary.shade_100}`,
      padding: '12px 16px',
      display: 'flex',
      gap: '12px',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      background: ({ isSelected }: IStyleProps) =>
        isSelected
          ? theme.palette.secondary.shade_100
          : theme.palette.basic.white,
    },
    offeringLeftSection: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
    selectAll: {
      padding: '12px 16px',
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      cursor: 'pointer',
    },
    cover_image: {
      width: '79.68px',
      height: '52px',
      background: '#D9D9D9',
      borderRadius: '5px',
    },
    status: {
      color: theme.palette.basic.white,
      padding: '0 8px',
      fontSize: '14px',
      lineHeight: '22px',
      borderRadius: '5px',
    },
    fine_pine: {
      background: theme.palette.fine_pine.main,
    },
    coral_red: {
      background: theme.palette.coral_red.main,
    },
    butterscotch_yellow: {
      background: theme.palette.butterscotch_yellow.main,
    },
    danger_red: {
      background: theme.palette.basic.danger_red,
    },
    font_14: {
      fontSize: '14px',
      lineHeight: '17px',
    },
    font_12: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    emptyOfferingWrap: {
      padding: '45.5px 24px 24px 24px',
    },
    emptyContent: {
      fontSize: '12px',
      marginTop: '14px',
      marginBottom: '24px',
    },
    drawerPaper: {
      maxHeight: '70%',
    },
    small_img: {
      width: '55px',
      height: '36px',
    },
    deleteAllChipsBtn: {
      padding: 0,
      background: '#D9D9D9',
    },
    crossIcon: {
      width: '21.8px',
      fill: theme.palette.secondary.main,
      height: '19px',
    },
    helper: {
      lineHeight: '14px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      fontFamily: theme?.typography?.fontFamily,
    },
    helperWrapper: {
      marginTop: '10px',
    },
    helperText: {
      fontSize: theme.spacing(4),
      color: theme?.palette?.secondary?.main,
    },
    errorWrapper: {
      marginTop: '7.28px',
      gap: theme.spacing(3),
    },
    errorIcon: {
      width: theme.spacing(5),
      height: '14px',
      color: theme?.palette?.basic?.danger_red,
    },
    errorText: {
      fontSize: '11px',
      color: theme?.palette?.basic?.danger_red,
    },
    '@media (max-width:780px)': {
      offering: {
        padding: '16.5px 16px 16px 16px',
      },
      cover_image: {
        width: '57px',
        height: '36px',
      },
      selectAll: {
        padding: '16.5px 16px 16px 16px',
      },
      emptyOfferingWrap: {
        padding: '45.5px 16px 24px 16px',
      },
    },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
