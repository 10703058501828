import Chip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globaltheme: any) => ({
    parent: {
      top: ({ top }) => top,
      transform: ({ translateY }) => `translateY(${translateY})`,
      position: 'absolute',
      right: ({ position }) => (position === 'left' ? 'auto' : '-8px'),
      left: ({ position }) => (position === 'left' ? '-8px' : 'auto'),
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      alignItems: ({ position }: RibbonProps) =>
        position === 'left' ? 'flex-start' : 'flex-end',
    },
    chipRoot: {
      padding: 0,
      position: 'relative',
      height: '22px',
      borderRadius: ({ position }) =>
        position === 'left' ? '2px 2px 2px 0px' : '2px 2px 0px 2px',
    },
    label: {
      padding: '0 8px',
      fontSize: '14px',
      color: globaltheme?.palette?.basic?.white,
      overflow: 'visible',
    },
    triangle: {
      width: 0,
      height: 0,
      borderTop: `6px solid ${globaltheme?.palette?.secondary?.shade_200}`,
      borderRight: ({ position }) =>
        position === 'left' ? '0' : '8px solid transparent',
      borderLeft: ({ position }) =>
        position === 'left' ? '8px solid transparent' : '0',
    },
    primary: {
      background: globaltheme?.palette?.primary?.main,
    },
    secondary: {
      background: globaltheme?.palette?.coral_red?.main,
    },
    danger: {
      background: globaltheme?.palette?.basic?.danger_red,
    },
    info: {
      background: globaltheme?.palette?.persian_blue?.main,
    },
    warning: {
      background: globaltheme?.palette?.butterscotch_yellow?.main,
    },
    success: {
      background: globaltheme?.palette?.fine_pine?.shade_600,
    },
  }),
  { name: 'ComponentLibrary' }
);

interface RibbonProps extends Omit<MuiChipProps, 'color'> {
  top: string;
  translateY?: string;
  wrapperClassName?: string;
  chipRootClassName?: string;
  labelClassName?: string;
  position: 'left' | 'right';
  color: 'primary' | 'secondary' | 'danger' | 'info' | 'warning' | 'success';
}

const Ribbon: FC<RibbonProps> = ({
  position = 'left',
  color = 'primary',
  label,
  top,
  wrapperClassName,
  chipRootClassName,
  labelClassName,
  translateY,
  ...restProps
}) => {
  const classes = useStyles({ position, color, top, translateY });

  return (
    <div className={classnames(classes.parent, wrapperClassName)}>
      <Chip
        classes={{
          root: classnames(classes.chipRoot, classes[color], chipRootClassName),
          label: classnames(classes.label, labelClassName),
        }}
        label={label}
        {...restProps}
      />
      <div className={classes.triangle}></div>
    </div>
  );
};

export default withGenerateClassName(Ribbon);
