import MultiSelect from './components/MultiSelect';
import SingleSelect from './components/SingleSelect';
import { ISelectProps } from './types';

const Select = ({ multiple, ...rest }: ISelectProps) => {
  const SelectComponent = multiple ? MultiSelect : SingleSelect;
  return <SelectComponent {...rest} />;
};

export default Select;
