import React from 'react';

import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core';
import { CloseRounded as CloseIcon } from '@material-ui/icons';
import classnames from 'classnames';

import Button from '../Button/Button';
import { useStyles } from './InteractiveTooltip.theme';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface TooltipProps extends MuiTooltipProps {
  onPrimaryBtnClick?: Function;
  showPrimaryBtn?: boolean;
  primaryBtnText?: string;
  onCloseClick?: () => void;
  customFooter?: React.ReactNode;
  separator?: boolean;
  totalSteps?: number;
  step?: number;
  footerClassName?: any;
  titleClassName?: any;
  tooltipClasses?: any;
  tooltipClassName?: any;
  arrowClassName?: any;
}

const InteractiveTooltip = ({
  title,
  showPrimaryBtn = true,
  primaryBtnText,
  onPrimaryBtnClick,
  onCloseClick,
  customFooter,
  separator,
  totalSteps,
  step,
  tooltipClasses,
  titleClassName,
  footerClassName,
  tooltipClassName,
  arrowClassName,
  ...restProps
}: TooltipProps) => {
  const hasSteps = step !== 0 && totalSteps !== 0;
  const classes = useStyles({ hasSteps });

  const footer = (
    <div className={classnames(classes.footer, footerClassName)}>
      {hasSteps && (
        <span className={classes.steps}>
          Step {step} of {totalSteps}
        </span>
      )}

      {showPrimaryBtn && (
        <Button
          size="thin"
          color="primary"
          buttonWrapperClassName={classes.buttonWrapperClassName}
          className={classes.button}
          onClick={onPrimaryBtnClick}
        >
          {primaryBtnText}
        </Button>
      )}
    </div>
  );

  const tooltipContent = (
    <>
      <div className={classnames(classes.title, titleClassName)}>
        <span>{title}</span>
        {onCloseClick ? <CloseIcon onClick={onCloseClick} /> : null}
      </div>

      {separator && <hr className={classes.separator} />}

      {customFooter || footer}
    </>
  );

  return (
    <MuiTooltip
      interactive
      arrow
      classes={{
        arrow: classnames(classes.arrow, arrowClassName),
        tooltip: classnames(classes.tooltip, tooltipClassName),
        ...tooltipClasses,
      }}
      title={tooltipContent}
      {...restProps}
    />
  );
};

export default withGenerateClassName(InteractiveTooltip);
