import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import classnames from 'classnames';

import MobilePopover, { IMobilePopover } from '../MobilePopover/MobilePopover';
import Button from '../Button/Button';
import useStyles from './MobileModal.styles';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface Action {
  label: string;
  onClick: () => void;
  className?: string;
  btnProps?: any;
}

export interface IMobileModalProps extends IMobilePopover {
  customFooter?: React.ReactNode;
  primaryAction?: Action;
  secondaryAction?: Action;
  header?: string | React.ReactNode;
  customHeader?: React.ReactNode;
  hideCross?: boolean;
  onClickCross?: (e: React.SyntheticEvent) => void;
  dialogHeaderClassName?: string;
  footerClassName?: string;
}

const MobileModal: React.FC<IMobileModalProps> = ({
  header,
  customHeader,
  customFooter,
  primaryAction,
  secondaryAction,
  hideCross,
  onClickCross,
  children,
  dialogHeaderClassName,
  footerClassName,
  ...restProps
}) => {
  const { onClose } = restProps;
  const classes = useStyles();

  return (
    <MobilePopover {...restProps}>
      {customHeader || (
        <div
          className={classnames(classes.dialogHeader, dialogHeaderClassName)}
        >
          {header}
          {!hideCross && (
            <IconButton
              disableRipple
              disableFocusRipples
              component="span"
              className={classes.dialogCrossIconBtn}
              onClick={onClickCross || onClose}
            >
              <CloseRoundedIcon
                viewBox="3 4 19.06 17.01"
                className={classes.dialogCrossIcon}
              />
            </IconButton>
          )}
        </div>
      )}

      {children}

      {customFooter || (
        <div className={classnames(classes.dialogFooter, footerClassName)}>
          {secondaryAction && (
            <Button
              onClick={secondaryAction.onClick}
              color="subtleSecondary"
              variant="text"
              size="medium"
              className={classnames(
                classes.footerBtn,
                classes.secondaryBtn,
                secondaryAction.className
              )}
              {...(secondaryAction.btnProps || {})}
            >
              {secondaryAction.label}
            </Button>
          )}
          {primaryAction && (
            <Button
              fullWidth={!secondaryAction}
              onClick={primaryAction.onClick}
              size="medium"
              color="primary"
              className={classnames(classes.footerBtn, primaryAction.className)}
              {...(primaryAction.btnProps || {})}
            >
              {primaryAction.label}
            </Button>
          )}
        </div>
      )}
    </MobilePopover>
  );
};

export default withGenerateClassName(MobileModal);
