import { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';
import MobilePopover from '../../../../MobilePopover/MobilePopover';

const useStyles = makeStyles(
  () => ({
    popoverPaper: {
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      background: '#fff !important',
      height: 'unset !important',
    },
  }),
  { name: 'ComponentLibrary' }
);
interface PopupProps {
  anchorEl: any;
  onClose: () => void;
  drawerOpen: boolean;
  usingDesktop: boolean;
  toggleDrawer: any;
}

const Popup: React.FC<PopupProps> = ({
  anchorEl,
  onClose,
  children,
  drawerOpen,
  toggleDrawer,
  usingDesktop,
}) => {
  const classes = useStyles();
  const isPopoverOpen = Boolean(anchorEl);
  const popOverId = isPopoverOpen ? 'simple-popover' : undefined;

  return usingDesktop ? (
    <Popover
      id={popOverId}
      open={drawerOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{ paper: classes.popoverPaper }}
    >
      {children}
    </Popover>
  ) : (
    <MobilePopover anchor="bottom" open={drawerOpen} onClose={onClose}>
      {children}
    </MobilePopover>
  );
};

export default Popup;
