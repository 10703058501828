import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '8px',
  },
  tableInput: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto auto',
    gap: '3px',
  },
  tableUnit: {
    width: '15px',
    height: '15px',
    border: `1px solid lightgray`,
  },
}));
