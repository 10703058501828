import { makeStyles } from '@material-ui/core';

interface StyleProps {
  hasSteps?: boolean;
}

export const useStyles = makeStyles(
  (theme: any) => ({
    arrow: {
      '&:before': {
        border: `1px solid ${theme?.palette?.primary?.main}`,
      },
      color: theme?.palette?.basic?.white,
    },
    tooltip: {
      backgroundColor: theme?.palette?.basic?.white,
      border: `1.5px solid ${theme?.palette?.primary?.main}`,
      color: '#373D3F',
      fontSize: '14px',
      fontWeight: 500,
      padding: '14px',
      borderRadius: '5px',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: theme?.typography?.fontFamily,
      '& span': {
        maxWidth: '198px',
      },
      '& svg': {
        marginRight: '-5.405px',
        color: theme?.palette?.primary?.main,
        cursor: 'pointer',
      },
    },
    button: {
      height: '24px',
      fontSize: '14px',
    },
    buttonWrapperClassName: {
      textAlign: 'right',
      marginTop: '2px',
    },
    separator: {
      background: theme?.palette?.secondary?.shade_100,
      height: '1.5px',
      margin: '12px 0px',
      border: 'none',
    },
    footer: {
      display: ({ hasSteps }: StyleProps) => (hasSteps ? 'flex' : 'block'),
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: theme?.typography?.fontFamily,
    },
    steps: {
      color: '#373D3F',
      fontSize: '14px',
    },
  }),
  { name: 'ComponentLibrary' }
);
