import React from 'react';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import Dropdown from '../../../Dropdown/Dropdown.js';
import { activeMark, addMarkData } from '../../utils/SlateUtilityFunctions';
import { useStyles } from '../Toolbar.styles.js';

interface ToolbarDropdownProps {
  format: string;
  options?: any[];
  editor: any;
}

export const ToolbarDropdown: React.FC<ToolbarDropdownProps> = ({
  format,
  options,
  editor,
}) => {
  const { formControlClassName, inputBaseRootClassName, selectClassName } =
    useStyles();

  const changeMarkData = (editor: any, value: any, format: string) => {
    addMarkData(editor, { format, value });
  };

  return (
    <Dropdown
      tall
      minWidth="116.56px"
      autoWidth
      value={activeMark(editor, format)}
      placeholder="Normal"
      handleChange={(value: any) => changeMarkData(editor, value, format)}
      IconComponent={ArrowDropDownRoundedIcon}
      formControlClassName={formControlClassName}
      selectClassName={selectClassName}
      inputBaseRootClassName={inputBaseRootClassName}
      options={options}
    />
  );
};
