import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  checked?: boolean;
  size: 'small' | 'large';
  color?: 'primary' | 'success';
  disabled?: boolean;
}

const useStyles = makeStyles(
  (theme: any) => ({
    rootWrapper: {
      position: 'relative',
      display: 'inline-block',
      borderRadius: '15px',
      padding: '2px',
      border: '2px solid transparent',
      '&:hover': {
        borderColor: '#493AB1',
      },
      pointerEvents: ({ disabled }: StyleProps) => (disabled ? 'none' : 'auto'),
    },
    switchBase: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      height: ({ size }: StyleProps) => (size === 'large' ? '20px' : '16px'),
      width: ({ size }: StyleProps) => (size === 'large' ? '40px' : '32px'),
      borderRadius: '10px',
      backgroundColor: ({ checked, color, disabled }: StyleProps) =>
        disabled
          ? '#F3F2F1'
          : checked
          ? color === 'success'
            ? '#00B77A'
            : '#493AB1'
          : '#A09A92',
      boxSizing: 'border-box',
      '& svg': {
        fontSize: '12px',
        color: ({ disabled }: StyleProps) => (disabled ? '#CFCDC9' : 'white'),
      },
    },
    root: {
      height: ({ size }: StyleProps) => (size === 'large' ? '20px' : '16px'),
      width: ({ size }: StyleProps) => (size === 'large' ? '40px' : '32px'),
      padding: 0,
      display: 'flex',
    },
    muiSwitchBase: {
      padding: 2,
    },
    thumb: {
      height: ({ size }: StyleProps) => (size === 'large' ? '16px' : '12px'),
      width: ({ size }: StyleProps) => (size === 'large' ? '16px' : '12px'),
      boxShadow: 'none',
      color: ({ disabled }: StyleProps) => (disabled ? '#CFCDC9' : 'white'),
    },
    track: {
      height: 'auto',
      borderRadius: '10px',
      opacity: 1,
      backgroundColor: 'transparent',
    },
    checked: {
      '&$checked': {
        transform: ({ size }: StyleProps) =>
          size === 'large' ? 'translateX(20px)' : 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'ComponentLibrary', index: 2 }
);

export default useStyles;
