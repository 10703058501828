
  export const convertStringToCSSObject = (styleString: string): { [key: string]: string } => {
    if(!styleString || styleString?.trim()?.length <= 0) return {};

    const stylesArray = styleString?.split(';');
    const cssObject: { [key: string]: string } = {};
  
    for (const style of stylesArray) {
      const [property, value] = style.split(':');
      if (property && value) {
        cssObject[property.trim()] = value.trim().replace(/['"]+/g, '');
      }
    }
  
    return cssObject;
  }

export const flattenHtmlWithStyles = (htmlString: string, config: string[]) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  function filterStylesByConfig(styleString: string, config: string[]): string {
    const styles = styleString.split(';').map((style) => style.trim());

    // Filter styles based on the config array
    const filteredStyles = styles.filter((style) => {
      const property = style.split(':')[0].trim();
      return config.includes(property);
    });

    // Reconstruct the style string
    const filteredStyleString = filteredStyles.join('; ');

    return filteredStyleString;
  }

  const mergeStyles = (el: HTMLElement) => {
    const elementStyles = el.getAttribute("style") || "";
    return filterStylesByConfig(elementStyles, config);
  };

  const applyStylesToElement = (el: HTMLElement, styles: string) => {
    const filteredStyles = filterStylesByConfig(`${styles};${el.getAttribute("style") || ""}`, config);
    el.setAttribute("style", filteredStyles);
  };

  const changeTagsToP = (element: HTMLElement) => {
    if (element.tagName !== 'P') {
      const newPTag = document.createElement('p');
      while (element.firstChild) {
        newPTag.appendChild(element.firstChild);
      }
      element.replaceWith(newPTag);
      return newPTag;
    }
    return element;
  };

  const unwrapElement = (el: HTMLElement) => {
    const parent = el.parentElement;
    parent?.removeAttribute("class");
    parent?.removeAttribute("id");
    const styles = mergeStyles(el);
    const children = Array.from(el.children);
    for (const child of children) {
      applyStylesToElement(child as HTMLElement, styles);
      if (parent) {
        parent.insertBefore(child, el);
      }
    }
    if (parent) {
      parent.removeChild(el);
    }
  };

  // Change elements to <p> tags and apply styles
  const changeHtmlTags = (element: HTMLElement) => {
    const children = Array.from(element.children);
    for (const child of children) {
      if (child?.children?.length > 0) {
        const newPTag = changeTagsToP(child as HTMLElement);
        changeHtmlTags(newPTag);
      } else {
        const newPTag = changeTagsToP(child as HTMLElement);
        const styles = child.getAttribute('style') || '';
        applyStylesToElement(newPTag, styles);
      }
    }
  };

  // Unwrap nested elements and apply styles, excluding 'height' property
  const removeNestedRecursively = (element: HTMLElement) => {
    const children = Array.from(element.children);
    for (const child of children) {
      if (child?.children?.length > 0) {
        removeNestedRecursively(child as HTMLElement);
      }
    }
    unwrapElement(element);
  };

  changeHtmlTags(tempDiv);

  removeNestedRecursively(tempDiv);
  // Remove empty <p> tags
  tempDiv.querySelectorAll('p').forEach((el) => {
    if (!el.innerHTML.trim()) {
      el.remove();
    }
  });

  return tempDiv.innerHTML;
}