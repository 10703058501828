import React from 'react';

interface TableProps {
  attributes?: any;
  element?: any;
}

const Table: React.FC<TableProps> = ({ attributes, children, element }) => {
  return (
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
        border: '1.5px solid #272522',
        tableLayout: 'fixed',
      }}
    >
      {children}
    </table>
  );
};

export default Table;
