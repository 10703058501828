import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { ProgressProps } from './Progress';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  {
    root: {
      position: 'relative',
      width: '100%',
    },
    stepperRoot: {
      flexGrow: 1,
      padding: 0,
      position: 'absolute',
      width: '100%',
      background: 'none',
    },
    progress: {
      background: 'none',
      height: '8px',
      width: '100%',
      '& *': {
        backgroundColor: '#00B779',
      },
    },
    progressBackground: {
      position: 'absolute',
      height: '6px',
      width: '100%',
      backgroundColor: '#E7E6E4',
      top: '2px',
    },
  },
  { name: 'ComponentLibrary' }
);

const ProgressBar: FC<Omit<ProgressProps, 'variant' | 'options'>> = ({
  stepsCount,
  activeStep,
  progressClassName,
  stepperClassName,
  classes: propClasses = {},
}) => {
  const classes = useStyles();
  const { progressBackground, customProgress, ...restClasses } = propClasses;

  return (
    <div className={classnames(classes.root, progressClassName)}>
      <div
        className={classnames(classes.progressBackground, progressBackground)}
      ></div>
      <MobileStepper
        variant="progress"
        steps={stepsCount + 1}
        position="static"
        activeStep={activeStep <= stepsCount ? activeStep : stepsCount}
        classes={{
          root: classnames(classes.stepperRoot, stepperClassName),
          progress: classnames(classes.progress, customProgress),
          ...restClasses,
        }}
        backButton={null}
        nextButton={null}
      />
    </div>
  );
};

export default withGenerateClassName(ProgressBar);
