import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Input from '../../../Input/Input';
import Button from '../../../Button/Button';

const useStyles = makeStyles(
  (theme: any) => ({
    container: {
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
    },
    header: {
      color: theme?.palette?.secondary?.main,
      padding: '0 16px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 24px',
      },
      fontSize: '18px',
      height: '59.5px',
      boxSizing: 'border-box',
      fontWeight: 700,
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerCloseIconBtn: {
      '&:hover': {
        background: theme?.palette?.basic?.white,
      },
      padding: '0',
    },
    headerCloseIcon: {
      width: '18.94px',
      height: '18.94px',
    },
    content: {
      padding: '20.5px 16px 46px 16px',
      [theme.breakpoints.up('sm')]: {
        padding: '20.5px 24px 20px 24px',
      },
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    footer: {
      height: '80px',
      boxSizing: 'border-box',
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '26px',
      padding: '0 16px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 24px',
        justifyContent: 'flex-end',
      },
    },
    removeBtn: {
      padding: '11px 16px',
    },
    saveBtn: {
      padding: '11px 24px',
    },
  }),
  { name: 'ComponentLibrary' }
);

interface ILinkForm {
  onAddLink: () => void;
  onRemoveLink: () => void;
  urlValue: string;
  onUrlChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  linkError?: any;
  hasUrl?: boolean;
}

const LinkForm: React.FC<ILinkForm> = ({
  urlValue,
  onUrlChange,
  onAddLink,
  onRemoveLink,
  onClose,
  linkError,
  hasUrl,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        Insert Link
        <IconButton
          disableRipple
          disableFocusRipple
          component="span"
          onClick={onClose}
          className={classes.headerCloseIconBtn}
        >
          <CloseRoundedIcon
            viewBox="3 4 19.06 17.01"
            className={classes.headerCloseIcon}
          />
        </IconButton>
      </Box>
      <Box className={classes.content}>
        <Input
          label="Link*"
          placeholder="https://example.com"
          size="small"
          value={urlValue}
          onChange={onUrlChange}
          fullWidth
          {...linkError}
        />
      </Box>

      <Box className={classes.footer}>
        {hasUrl && (
          <Button
            color="danger"
            size="medium"
            onClick={onRemoveLink}
            className={classes.removeBtn}
          >
            Remove Link
          </Button>
        )}
        <Button
          color="primary"
          size="medium"
          disabled={!urlValue || linkError.error}
          onClick={onAddLink}
          className={classes.saveBtn}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default LinkForm;
