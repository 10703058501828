import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: '8px',
    overflow: 'hidden',
  },
  colorOptions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto auto auto',
    alignItems: 'center',
    gap: '5px',
  },
  option: {
    width: '16px',
    height: '16px',
    backgroundColor: theme?.palette?.basic?.black,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    width: '100%',
  },
  input: {
    width: '100px',
  },
  removeBtn: {
    marginTop: '8px',
  },
}));
